import React, { Component } from 'react';

class Privacypolicy extends Component{
    render(){
        return(
            <div style={{width:'70%',marginLeft:'15%'}} >
<p ><br />
<strong>VEROHIVE PRIVACY POLICY</strong></p>
<p><br />
<strong>A. Introduction</strong></p>
<p>1. VEROHive, a brand of Megahoot, LLC , values its visitors’ privacy and we 
are committed to safeguarding it. This privacy policy is effective August 1, 
2020 and it summarizes what information we might collect from a registered user 
or other visitor (“You”), and what we will and will not do with your personal 
information.</p>
<p>2. Please note that this privacy policy does not govern the collection and 
use of information by companies that Megahoot, LLC does not control, nor by 
individuals not employed or managed by Megahoot, LLC. If you visit a website 
that we are mentioned n in or linked to, be sure to review its privacy policy 
before providing such as website any personal information.</p>
<p>3. Consenting to our use of cookies in accordance with the terms of this 
policy when you first visit our website permits us to use cookies every time you 
visit our website.</p>
<p><strong>B. Collecting personal information</strong></p>
<p>What we do with your personally identifiable information<br />
It is always up to you whether to disclose personally identifiable information 
to us, although if you elect not to do so, we reserve the right not to register 
you as a user or provide you with any products or services. “Personally 
identifiable information” means information that can be used to identify you as 
an individual.</p>
<p>The following types of personal information may be collected, stored, and 
used:</p>
<p>1. information about your computer including your IP address, geographical 
location, browser type and version, and operating system;</p>
<p>2. session data for your login session, so that our network can communicate 
with your computer or device while you are logged in.</p>
<p>3. information about your visits to and use of this website including the 
referral source, length of visit, page views, and website navigation paths;</p>
<p>4. information, such as your email address, that you enter when you register 
with VEROHive;</p>
<p>5. information that you enter when you create your profile on VEROHive, for 
example, your name, profile pictures, gender, birthday, company name and 
employment details;</p>
<p>6. information, such as your name and email address, that you enter in order 
to set up subscriptions to our emails and/or newsletters;</p>
<p>7. information that you enter while using the services on VEROHive;</p>
<p>8. information that is generated while using our applications or website, 
including when, how often, and under what circumstances you use it;</p>
<p>9. information relating to any upgrades that you make, services that you use, 
or transactions you make through our applications or website, which includes 
your name, address, telephone number, email address, and credit card details (if 
applicable) [THIS MAY NOT BE APPROPRIATE IF CREDIT CARD INFORMATION IS HANDLED 
BY A PAYMENT PROCESSOR];</p>
<p>10. information contained in any communications that you send to us by email 
or through our applications or website, including its communication content and 
metadata;</p>
<p>11. any other personal information that you send to us.</p>
<p><strong>C. Identifiable Information</strong></p>
<p>If you do provide personally identifiable information to us, either directly 
or through a reseller or other business partner, we will:</p>
<p>1. not sell or rent it to a third party, we may use your contact information 
to provide you with information we believe you need to know or may find useful, 
such as (for example) news about our services and products and modifications to 
the Terms of Service;</p>
<p>2. take commercially reasonable precautions to protect the information from 
loss, misuse and unauthorized access, disclosure, alteration and destruction;</p>
<p>3. not use or disclose the information except:<br />
&nbsp; 3.1. as necessary to provide services or products you have ordered, by 
providing it to a carrier to deliver products you have ordered;<br />
&nbsp; 3.2. in other ways described in this privacy policy or to which you have 
otherwise consented;<br />
&nbsp; 3.3. in the aggregate with other information in such a way so that your 
identity cannot reasonably be determined (for example, statistical 
compilations);<br />
&nbsp; 3.4. as required by law in response to a subpoena or search warrant;<br />
&nbsp; 3.5. to outside auditors who have agreed to keep the information 
confidential;<br />
&nbsp; 3.6. as necessary to enforce the Terms of Service; <br />
&nbsp; 3.7. as necessary to protect the rights, safety, or property of VEROHive 
and Megahoot, LLC, its users, or others; this may include exchanging information 
with other organizations for fraud protection and/or risk reduction.</p>
<p><strong>D. Prior to Disclosing Personal Information;</strong></p>
<p>Before you disclose to us the personal information of another person, you 
must obtain that person’s consent to both the disclosure and the processing of 
that personal information in accordance with this policy</p>
<p><strong>E. Using your personal information</strong></p>
<p>Personal information submitted to us through VEROHive will be used for the 
purposes specified in this policy or on the relevant pages of VEROHive’s web, 
desktop or mobile applications. We may use your personal information for the 
following:</p>
<p>1. administering our website and business;<br />
2. personalizing VEROHive for you;<br />
3. enabling your use of the services available on VEROHive;<br />
4. supplying services purchased through VEROHive;<br />
5. sending statements, invoices, and payment reminders to you, and collecting 
payments from you;<br />
6. sending you non-marketing commercial communications;<br />
7. sending you email notifications that you have specifically requested;<br />
8. sending you our email newsletter, if you have requested it (you can inform us 
at any time if you no longer require the newsletter);<br />
9. sending you marketing communications relating to our business or our family 
of brands and companies carefully- we will never share your personal information 
or contact details to third parties;<br />
10. dealing with inquiries and complaints made by or about you relating to 
VEROHive;<br />
11. keeping VEROHive secure and prevent fraud;<br />
12. verifying compliance with the terms and conditions governing the use of 
VEROHive’s web, desktop and mobile applications, we will not monitor private 
messages or save such data on our network, all communication from one user to 
another is private and confidential;</p>
<p>If you submit personal information for publication on VEROHive, we will 
publish and otherwise use that information in accordance with the license you 
grant to us.</p>
<p>Your privacy settings can be used to limit the publication of your 
information on VEROHive and can be adjusted using privacy controls on VEROHive.</p>
<p>We will never share your personal information to any third party for their or 
any other third party’s marketing purposes or other.</p>
<p><strong>F. Disclosing personal information</strong></p>
<p>We may disclose your personal information to any of our employees, officers, 
insurers, professional advisers, agents, or subcontractors as reasonably 
necessary for the purposes set out in this policy.<br />
We may disclose your personal information to any member of our group of 
companies (this means our subsidiaries, our ultimate holding company and all its 
subsidiaries) as reasonably necessary for the purposes set out in this policy.We 
may disclose your personal information:</p>
<p>1. to the extent that we are required to do so by law;<br />
2. in connection with any ongoing or prospective legal proceedings;<br />
3. in order to establish, exercise, or defend our legal rights (including 
providing information to others for the purposes of fraud prevention and 
reducing credit risk);<br />
4. to the purchaser (or prospective purchaser) of any business or asset that we 
are (or are contemplating) selling; and<br />
5. to any person who we reasonably believe may apply to a court or other 
competent authority for disclosure of that personal information where, in our 
reasonable opinion, such court or authority would be reasonably likely to order 
disclosure of that personal information.<br />
Except as provided in this policy, we will not provide your personal information 
to third parties.</p>
<p><strong>G. International data transfers</strong></p>
<p>1. Information that we collect may be stored, processed in, and transferred 
between any of the countries in which we operate in order to enable us to use 
the information in accordance with this policy.</p>
<p>2. Information that we collect may be transferred to the following countries 
which do not have data protection laws equivalent to those in force in the 
European Economic Area: the United States of 
America,Russia,
Japan, Asia, IndoAsia, the 
United Kingdom and India.</p>
<p>3. Personal information that you may publish on VEROHive or submit for 
publication on our applications or website may be available, via the internet, 
around the world. We cannot prevent the use or misuse of such information by 
others.</p>
<p>4. You expressly agree to the transfers of personal information described in 
this and Section F.</p>
<p><strong>H. Retaining personal information</strong></p>
<p>1. This Section H sets out our data retention policies and procedure, which 
are designed to help ensure that we comply with our legal obligations regarding 
the retention and deletion of personal information.</p>
<p>2. Personal information that we process for any purpose or purposes shall not 
be kept for longer than is necessary for that purpose or those purposes.</p>
<p>3. Without prejudice to article G-2, we will usually delete personal data 
falling within the categories set out below at the date/time set out below:<br />
a. personal data type will be deleted daily; and</p>
<p>4. Notwithstanding the other provisions of this Section H, we will retain 
documents (including electronic documents) containing personal data:<br />
a. to the extent that we are required to do so by law;<br />
b. if we believe that the documents may be relevant to any ongoing or 
prospective legal proceedings; and<br />
c. in order to establish, exercise, or defend our legal rights (including 
providing information to others for the purposes of fraud prevention and 
reducing credit risk).</p>
<p><strong>I. Security of your personal information</strong></p>
<p>1. We will take reasonable technical and organizational precautions to 
prevent the loss, misuse, or alteration of your personal information.</p>
<p>2. We will store all the personal information you provide on our secure 
(password- and firewall-protected) servers.</p>
<p>3. All electronic financial transactions entered into through our website 
will be protected by encryption technology.</p>
<p>4. You acknowledge that the transmission of information over the internet is 
inherently insecure, and we cannot guarantee the security of data sent over the 
internet.</p>
<p>5. You are responsible for keeping the password you use for accessing 
VEROHive confidential; we will not ask you for your password (except when you 
log in to our applications or website).</p>
<p><strong>J. Amendments</strong></p>
<p>We may update this policy from time to time by publishing a new version on 
VEROHive. You should check this page occasionally to ensure you understand any 
changes to this policy. We may notify you of changes to this policy by email or 
through the private messaging system on VEROHive.</p>
<p><strong>K. Your rights</strong></p>
<p>You may instruct us to provide you with any personal information we hold 
about you; provision of such information will be subject to the following:<br />
1. the payment of a fee : and<br />
2. the supply of appropriate evidence of your identity such as a color photocopy 
of a government issued photo identification or other that would be deemed 
acceptable proof of identity.<br />
We may withhold personal information that you request to the extent permitted by 
law.</p>
<p><strong>L. Updating information</strong></p>
<p>Please let us know if the personal information that we hold about you needs 
to be corrected or updated.</p>
<p><strong>M. Cookies</strong></p>
<p>VEROHive uses cookies. A cookie is a file containing an identifier (a string 
of letters and numbers) that is sent by a web server to a web browser and is 
stored by the browser. The identifier is then sent back to the server each time 
the browser requests a page from the server. Cookies may be either “persistent” 
cookies or “session” cookies: a persistent cookie will be stored by a web 
browser and will remain valid until its set expiry date, unless deleted by the 
user before the expiry date; a session cookie, on the other hand, will expire at 
the end of the user session, when the web browser is closed. Cookies do not 
typically contain any information that personally identifies a user, but 
personal information that we store about you may be linked to the information 
stored in and obtained from session cookies.</p>
<p>1. Most browsers allow you to refuse to accept cookies—for example:</p>
<p>a. in Internet Explorer (version 10) you can block cookies using the cookie 
handling override settings available by clicking “Tools,” “Internet Options,” 
“Privacy,” and then “Advanced”;<br />
b. in Firefox (version 24) you can block all cookies by clicking “Tools,” 
“Options,” “Privacy,” selecting “Use custom settings for history” from the 
drop-down menu, and unticking “Accept cookies from sites”; and<br />
c. in Chrome (version 29), you can block all cookies by accessing the “Customize 
and control” menu, and clicking “Settings,” “Show advanced settings,” and 
“Content settings,” and then selecting “Block sites from setting any data” under 
the “Cookies” heading. <br />
d. Blocking all cookies will have a negative impact upon the usability of many 
websites. If you block cookies, you may not be able to use all the features on 
VEROHive. You can delete cookies already stored on your computer—for example:<br />
e. in Internet Explorer (version 10), you must manually delete cookie files (you 
can find instructions for doing so at http://support.microsoft.com/kb/278835 );<br />
f. in Firefox (version 24), you can delete cookies by clicking “Tools,” 
“Options,” and “Privacy”, then selecting “Use custom settings for history”, 
clicking “Show Cookies,” and then clicking “Remove All Cookies”; and<br />
g. in Chrome (version 29), you can delete all cookies by accessing the 
“Customize and control” menu, and clicking “Settings,” “Show advanced settings,” 
and “Clear browsing data,” and then selecting “Delete cookies and other site and 
plug-in data” before clicking “Clear browsing data.” Deleting cookies will have 
a negative impact on the usability of many websites.</p>

            </div>
        )
    }
} 
export default Privacypolicy;