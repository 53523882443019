import React, { Component } from "react";
import { Link } from "react-router-dom";
import logo from './verohivelogo.png';

class ForgetPassword extends Component {
  state = {
    email: "",
    password: "",
    isSubmitting: false,
    errorMessage: "",
    code: "",
    verifycode: "",
    confirmpassword: "",
    show: false,
    invalidcode: '',
    passworddidnotmatch: '',
    passwordchangesuccess: '',
    enteremail: '',
    checkyouremail: '',


  };
  renderLoading() {
    return <div>Logging in...</div>;
  }


  backMe = () => {
    this.props.history.goBack()

  }

  
  onChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  async onSubmit1(e) {
    e.preventDefault();
    const { email, verifycode, code, password, confirmpassword } = this.state;
    if (

      code != verifycode
    ) {
      return this.setState({ invalidcode: "sorry,you entered an invalid code" });

    }
    else if (

      password != confirmpassword
    ) {

      return this.setState({ passworddidnotmatch: "Password do not match" });
    }
    // this.setState({ isSubmitting: true });
    else {
      fetch("/updatepassword", {
        method: "post",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          email,
          password

        })
      })

        .then(() => {

          this.setState({ passwordchangesuccess: "password changed successfully" });

        })
        .catch(err => console.log(err))
    }

  }

  async onSubmit(e) {
    console.log("Sss")
    e.preventDefault();
    const { email } = this.state;
    if (

      email == ""
    ) {

      return this.setState({ enteremail: "Please enter your email" });

    }
    // this.setState({ isSubmitting: true });

    var rand;
    var rand1 = Math.floor((Math.random() * 100) + 54);
    var rand2 = Math.floor((Math.random() * 100) + 54);

    rand = rand1.toString() + rand2.toString();

    this.setState({
      code: rand
    })
    fetch("/nodemailer", {
      method: "post",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        To: email,
        subject: "Please enter this code to change your password",
        text: "Your Verification Code is" + rand,
        html: `<img src="./verohivelogo.png" style="width: 30%;">
          <h1 style="color: #5b5b5b;"> VEROHive</h1>
          <h3 style="color: #757575;">Please change your password by using the OTP below</h3>
         <p>${rand}</p>
        
          
          
          <h4 style="color: #757575;">Cheers!</h4>
          <h4 style="color: #757575;">VEROHive Team</h4>"
          `

      })
    })

      .then(() => {




        this.setState({ 
          checkyouremail: "Check Your Email to Change Your Password" ,
      });
        this.setState({
          show: true,
         
        })
      })
      .catch(err => console.log(err))
  }



  render() {
    return (
      <div>

        <div className="header">
          <img src={logo} className='logo-vero'></img><h4 style={{ color: 'white', marginLeft: '45%' }}>V4.1.1</h4>
          <button className="btn-cancel" onClick={() => { this.backMe() }}>Go Back</button>
       
        </div>
        {/* <img src={logo} className='logo-vero'></img> */}

        {
          this.state.invalidcode != "" ? (
            <div style={{ zIndex: "10000000", backgroundColor: "white", padding: '10px', color: 'grey', fontSize: '1.4rem', position: 'absolute', top: '10%', left: '40%' }}>
              <span> {this.state.invalidcode}<br></br></span>
              <span><button style={{ backgroundColor: "#4FADD3", color: 'white', border: 'none' }}
                onClick={() => this.setState({
                  invalidcode: "",

                })}>OK</button></span>
            </div>
          ) : null
        }

        {
          this.state.passworddidnotmatch != "" ? (
            <div style={{ zIndex: "10000000", backgroundColor: "white", padding: '10px', color: 'grey', fontSize: '1.4rem', position: 'absolute', top: '10%', left: '40%' }}>
              <span> {this.state.passworddidnotmatch}<br></br></span>
              <span><button style={{ backgroundColor: "#4FADD3", color: 'white', border: 'none' }}
                onClick={() => this.setState({
                  passworddidnotmatch: "",

                })}>OK</button></span>
            </div>
          ) : null
        }

        {
          this.state.passwordchangesuccess != "" ? (
            <div style={{ zIndex: "10000000", backgroundColor: "white", padding: '10px', color: 'grey', fontSize: '1.4rem', position: 'absolute', top: '10%', left: '40%' }}>
              <span> {this.state.passwordchangesuccess}<br></br></span>
              <span><button style={{ backgroundColor: "#4FADD3", color: 'white', border: 'none' }}
                onClick={() => this.setState({
                  passwordchangesuccess: "",

                })}>OK</button></span>
            </div>
          ) : null
        }

        {
          this.state.enteremail != "" ? (
            <div style={{ zIndex: "10000000", backgroundColor: "white", padding: '10px', color: 'grey', fontSize: '1.4rem', position: 'absolute', top: '10%', left: '40%' }}>
              <span> {this.state.enteremail}<br></br></span>
              <span><button style={{ backgroundColor: "#4FADD3", color: 'white', border: 'none' }}
                onClick={() => this.setState({
                  enteremail: "",

                })}>OK</button></span>
            </div>
          ) : null
        }

        {
          this.state.checkyouremail != "" ? (
            <div style={{ zIndex: "10000000", backgroundColor: "white", padding: '10px', color: 'grey', fontSize: '1.4rem', position: 'absolute', top: '10%', left: '40%' }}>
              <span> {this.state.checkyouremail}<br></br></span>
              <span><button style={{ backgroundColor: "#4FADD3", color: 'white', border: 'none' }}
                onClick={() => this.setState({
                  checkyouremail: "",

                })}>OK</button></span>
            </div>
          ) : null
        }
        {this.state.show ? (<div>

          <form className="form-login"

            onSubmit={e => {
              this.onSubmit1(e);
            }}
          >
            <h2>Change your password</h2>
            <div className="username" >

              {/* <i className="fa fa-user-circle-o icon  iconinput"></i> */}
              <h4>
                Enter OTP: <span style={{ color: "red" }}>*</span>
              </h4>
              <input className="input"
                type="password"
                name="verifycode"
                placeholder="code"
                ref="verifycode"
                onChange={this.onChange}
              />

            </div>
            <div className="password">
              <h4>
                New  Password: <span style={{ color: "red" }}>*</span>
              </h4>

              <input className="input"
                type="password"
                name="password"
                placeholder="Password"
                ref="password"
                onChange={this.onChange}
              />
            </div>
            <div className="password">
              <h4>
                Confirm  Password: <span style={{ color: "red" }}>*</span>
              </h4>

              <input className="input"
                type="password"
                name="confirmpassword"
                placeholder="confirmpassword"
                ref="confirmpassword"
                onChange={this.onChange}
              />
            </div>


            <div className="submit">

              <input type="submit" value="Submit" className="btn" />


            </div>
            <div className="message" style={{ display: 'none' }}>
              {this.state.isSubmitting ? "Checking details...." : ""}
            </div>
            <div className="errorMessage">{this.state.errorMessage}</div>

          </form>
        </div>) : (<div>
          <form className="form-login"

            onSubmit={e => {
              this.onSubmit(e);
            }}
          >
            <h2>Enter your registered email</h2>
            <div className="username" >


              <input className="input"
                type="text"
                name="email"
                placeholder="email"
                ref="email"
                onChange={this.onChange}
              />

            </div>


            <div className="submit">

              <input type="submit" value="Submit" className="btn" />


            </div>




            <div className="message" style={{ display: 'none' }}>
              {this.state.isSubmitting ? "Checking details...." : ""}
            </div>
            <div className="errorMessage">{this.state.errorMessage}</div>

          </form>

        </div>)
        }

        <div className='footer' >(c)VEROHive.All Rights Reserved</div>

      </div>
    );
  }
}

export default ForgetPassword;
