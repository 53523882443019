import React, { Component } from 'react'
import io from 'socket.io-client';
import logo from './verohivelogo.png';
import infoicon from './imgs/infoicon.png';
import receptionVideo from './video/Veroreception.m4v';
import kurentoUtils from 'kurento-utils';

import {
    createMuiTheme,
    MuiThemeProvider,
    withStyles
} from "@material-ui/core/styles";
import { Tooltip } from '@material-ui/core';
class WaitingRoom extends Component {
    constructor(props) {
        super(props)
        this.state = {
            myid: "",
            myroomcode: "",
            firstName: '',
            lastName: '',
            username: '',
            privatekey: '',
            email: '',
            hostroomcode: '',
            infoboxcontainer: '',
            infobox: '',
            waitingContainer: '',
            financeVideo: '',
            musicVideo: '',
            newsVideo: '',
            hostname: '',
            meetingroomname: '',
            cnaNews:'',
            Travel:'',
            isViaLink:''

        }

        this.socket = io.connect()
    }





    showWaitingContent = () => {
        this.setState({
            waitingContainer: 'showme'
        })
    }

    dashboard = () => {
        
        if (this.state.isViaLink == "true") {
            window.location.href = "https://www.verohive.com/";
          } else {
            this.props.history.push('/private', {
                username: this.state.id
            })
           
          }
       
    }
    closeApp = () => {
       
        window.location.replace = "https://www.verohive.com/";
    }
    componentDidMount() {
        // document.addEventListener('contextmenu', (e) => {
        //     e.preventDefault();
        // });
      
        var camMode =this.props.location.state.camMode

        var divRoomSelection = document.getElementById('roomSelection');
        // var divMeetingRoom = document.getElementById('meetingRoom');

        // var inputRoom = document.getElementById('room');
        var inputName = document.getElementById('name');
        var participants = {};
        var mysocketm = io();
        var roomName;
        var userName;

        var myuser = "Test";
        divRoomSelection.style = "display: none";
        // var customFacingMode = this.props.location.state.facingMode;
        // divMeetingRoom.style = "display:flex;flex-wrap: wrap;position:absolute;left:40px;top:60px";
        function autojoinmeet() {
            roomName = Math.random();
            inputName.value = myuser;
            console.log(roomName)
            if (roomName === '' || userName === '') {
                console.log('Room and Name are required!');
            } else {
                var message = {
                    event: 'joinRoom',
                    userName: myuser,
                    roomName: roomName
                }
                sendMessage(message);

            }
        }


        document.getElementById('camCheck').onclick = function () {
            if (document.getElementById('camCheck').style.color == "blue") {
                document.getElementById('camCheck').style.color = "red";

            } else {
                document.getElementById('camCheck').style.color = "blue"
            }

            autojoinmeet()
        }
        document.getElementById('camCheck').click();
        mysocketm.on('messageks', message => {
            console.log('Message received: ' + message.event);

            switch (message.event) {
                case 'newParticipantArrived':
                    receiveVideo(message.userid, message.username);
                    break;
                case 'existingParticipants':
                    onExistingParticipants(message.userid, message.existingUsers);
                    break;
                case 'receiveVideoAnswer':
                    onReceiveVideoAnswer(message.senderid, message.sdpAnswer);
                    break;
                case 'candidate':
                    addIceCandidate(message.userid, message.candidate);
                    break;
            }
        });


        // handlers functions
        function receiveVideo(userid, username) {

        }




        function onExistingParticipants(userid, existingUsers) {

            var video = document.getElementById('checkCam');
            video.poster="https://mhiservers.com/uO0muCuiv37bWZ0iiaPfsWUheuH6tGSgAssets/aB17BMXtfrcywe6Jh0RUMcN4dARoM6kyimages/WKjKIwwT%7Dj;tTcp4&tG9*eNC]gIElbsystem/VeroDealRoom_p.jpg"
      
            video.id = userid;

            // video.style.width = "150px"
            // var myheight = (video.style.width / 16) * 9
            // video.style.height = myheight
            video.autoplay = true;
            video.controls = true;

            var user = {
                id: userid,
                username: userName,
                video: video,
                rtcPeer: null
            }


            participants[user.id] = user;

            var constraints = {
                audio: true,
                // video: {
                //   mandatory: {
                //     minWidth: 1280,
                //     minHeight: 720,
                //     maxFrameRate: 15,
                //     minFrameRate: 15
                //   },

                //   optional: [{ aspectRatio: 16 / 9 }]
                // }
                video: {
                    // facingMode: {exact:customFacingMode},
                    deviceId:camMode,
                    frameRate: {
                        min: 1, ideal: 15, max: 30
                    },
                    width: {
                        min: 640, ideal: 1280, max: 1280
                    },
                    height: {
                        min: 360, ideal: 720, max: 720
                    }
                }
            };

            var options = {
                localVideo: video,
                mediaConstraints: constraints,
                onicecandidate: onIceCandidate
            }



            user.rtcPeer = kurentoUtils.WebRtcPeer.WebRtcPeerSendonly(options,
                function (err) {
                    if (err) {
                        return console.error(err);
                    }
                    this.generateOffer(onOffer)
                }
            );

            existingUsers.forEach(function (element) {
                receiveVideo(element.id, element.name);
            });

            var onOffer = function (err, offer, wp) {
                console.log('sending offer');
                var message = {
                    event: 'receiveVideoFrom',
                    userid: user.id,
                    roomName: roomName,
                    sdpOffer: offer
                }
                sendMessage(message);
            }

            function onIceCandidate(candidate, wp) {
                console.log('sending ice candidates');
                var message = {
                    event: 'candidate',
                    userid: user.id,
                    roomName: roomName,
                    candidate: candidate
                }
                sendMessage(message);

            }








            // videomute.onclick = function () {

            //     const mediaStream = video.srcObject;
            //     const videoTracks = mediaStream.getVideoTracks();
            //     if (videomute.style.color == "blue") {

            //         videomute.style.color = "red"

            //         videoTracks.forEach(track => track.enabled = false)
            //     }
            //     else if (videomute.style.color == "red") {
            //         videomute.style.color = "blue"


            //         videoTracks.forEach(track => track.enabled = true)
            //     }



            // }

            // audiomute.onclick = function () {
            //     const mediaStream = video.srcObject;
            //     const AudioTracks = mediaStream.getAudioTracks();
            //     if (audiomute.style.color == "blue") {

            //         audiomute.style.color = "red"
            //         AudioTracks.forEach(track => track.enabled = false)
            //     }
            //     else if (audiomute.style.color == "red") {
            //         audiomute.style.color = "blue"
            //         AudioTracks.forEach(track => track.enabled = true)
            //     }
            // }

            function dispose() {
                if (user.rtcPeer) {
                    user.rtcPeer.dispose();
                    user.rtcPeer = null;
                }
                video.remove()
            }

        }


        function onReceiveVideoAnswer(senderid, sdpAnswer) {
            participants[senderid].rtcPeer.processAnswer(sdpAnswer);
        }



        function addIceCandidate(userid, candidate) {
            participants[userid].rtcPeer.addIceCandidate(candidate);
        }

        // utilities

        function sendMessage(message) {
            console.log('sending ' + message.event + ' message to server');
            // mysocketm.emit('imcominginroom', () => {
            //     console.log('I m joining the room')
            // })
            mysocketm.emit('messageksc', message);

        }









       




        document.onkeydown = function (e) {
            if (e.keyCode == 123) {
                return false;
            }
            if (e.ctrlKey && e.shiftKey && e.keyCode == 'I'.charCodeAt(0)) {
                return false;
            }
            if (e.ctrlKey && e.shiftKey && e.keyCode == 'J'.charCodeAt(0)) {
                return false;
            }
            if (e.ctrlKey && e.keyCode == 'U'.charCodeAt(0)) {
                return false;
            }
        }



        function mobileview() {
            if (window.innerWidth <= 800) {
                let video = document.getElementById('receptionvideo');
                let videocamcheck = document.getElementById('checkCam');

                video.style.width = '88vw';
                video.style.marginTop = "0";
                video.style.position = "absolute";
                video.style.top = "15vh"

                video.style.marginLeft = "0";

                videocamcheck.style.width = '88vw';
                videocamcheck.style.marginTop = "210";
                videocamcheck.style.position = "absolute";
                videocamcheck.style.top = "44vh";

                videocamcheck.style.marginLeft = "0";
               

                document.getElementById('bottomcontrolsid').style.top = "80vh"

            }
        }
        mobileview()

        if (this.props.location.state != undefined) {
            this.setState({
                type: this.props.location.state.room_code,
                id: this.props.location.state.username,
                meetingperson: this.props.location.state.type,
                email: this.props.location.state.email,
                firstname: this.props.location.state.firstname,
                lastname: this.props.location.state.lastName,
                organization: this.props.location.state.organization,
                isViaLink:this.props.location.state.isViaLink,
                camMode:this.props.location.state.camMode
            })

            // this.viewfollowing(this.props.location.state.privatekey)
        }
        else {
            this.setState({
                type: window.location.href.substr(36),
                id: localStorage.getItem('user'),
                meetingperson: 'client'
            })

        }
        this.setState({
            myroomcode: this.props.location.search.substr(4),
            firstName: this.props.location.state.firstname,
            lastName: this.props.location.state.lastName,
            username: this.props.location.state.id,
            privatekey: this.props.location.state.privatekey,
            email: this.props.location.state.email,
            organization: this.props.location.state.organization,
            isViaLink:this.props.location.state.isViaLink,
            camMode:this.props.location.state.camMode
        })
        this.socket.emit('waitingRoom', () => {
            console.log("sent req skynow")
        })

        this.socket.once('yourhostwaslate', (data) => {
            if (data == this.state.myroomcode) {

                this.socket.emit('allowme', 
                { 
                    id: this.state.myid, 
                    room: this.state.myroomcode, 
                    name: this.state.firstname + " " + this.state.lastName,
                    veroKey:this.props.location.state.privatekey 
                })
                if (document.getElementById('hosthasarrived').style.display == "none") {
                    document.getElementById('hosthasarrived').style.display = "block"
                }

                if (document.getElementById('waitingforhost').style.display == "block") {
                    document.getElementById('waitingforhost').style.display = "none"
                }
            }
        })
        this.socket.once('yourid', (id) => {
            this.setState({
                myid: id
            })
            this.socket.emit('allowme',
             { 
                   id: this.state.myid, 
                   room: this.state.myroomcode, 
                   name: this.state.firstname + " " + this.state.lastName,
                   veroKey:this.props.location.state.privatekey 
                })

        })
        this.socket.on('yourhostinfo', (data) => {

            if (data.room_code == this.state.myroomcode) {
                this.setState({
                    hostname: data.hostname,
                    meetingroomname: data.roomname
                });
                if (document.getElementById('hosthasarrived').style.display == "none") {
                    document.getElementById('hosthasarrived').style.display = "block"
                }

                if (document.getElementById('waitingforhost').style.display == "block") {
                    document.getElementById('waitingforhost').style.display = "none"
                }
            }
        })

        this.socket.on('comeinroom', (id,existingUsers) => {
           
                
            if (this.state.myid == id) {
                // this.socket.disconnect()
                this.props.history.push({
                    pathname: '/videochat',
                    search: "?" + new URLSearchParams({ id: this.state.myroomcode }).toString(),
                    state: {
                        type: 'client',
                        room_code: this.state.myroomcode,
                        username: this.state.id,
                        privatekey: this.state.privatekey,
                        email: this.state.email,
                        firstname: this.state.firstname,
                        lastName: this.state.lastName,
                        organization: this.state.organization,
                        isViaLink:this.state.isViaLink,
                        screenSource:this.props.location.state.screenSource,
                        existingUsers:existingUsers,
                        camMode:this.props.location.state.camMode

                    }
                })
            }

        })

        this.socket.on('requestRejectedForYou',data=>{
            if(this.state.myid==data.myid){


                if (this.state.isViaLink == "true") {
                    window.location.href = "https://www.verohive.com/";
                  } else {
                   this.dashboard()
                  }
            }
        })

       

        // window.addEventListener("beforeunload",  (e)=> {
        //     e.preventDefault();
        //     // e.returnValue = '';
        //    this.closeApp()
           
        //   });
         

          window.onbeforeunload = ()=> { 
            window.setTimeout(()=> { 
              this.dashboard()
            }, 0); 
            window.onbeforeunload = null; // necessary to prevent infinite loop, that kills your browser 
        }


    }


    // detectwebcam = () => {
    //     if (document.getElementById('camCheck').style.color == "blue") {
    //         document.getElementById('camCheck').style.color = "red";

    //     } else {
    //         document.getElementById('camCheck').style.color = "blue"
    //     }
    //     // function detectWebcam(callback) {
    //     //     let md = navigator.mediaDevices;
    //     //     if (!md || !md.enumerateDevices) return callback(false);
    //     //     md.enumerateDevices().then(devices => {
    //     //       callback(devices.some(device => 'videoinput' === device.kind));
    //     //     })
    //     //   }

    //     //   detectWebcam(function(hasWebcam) {
    //     //       if(!hasWebcam){
    //     //         alert('Webcam is not connected');
    //     //       }

    //     //   })
    //     this.cameraCheck()


    // }
    // cameraCheck = () => {

    //     if (navigator.mediaDevices.getUserMedia) {
    //         navigator.mediaDevices.getUserMedia({ video: true })
    //             .then(function (stream) {
    //                 let video = document.getElementById('checkCam')

    //                 video.srcObject = stream;
    //                 video.controls = "true";

    //             })
    //             .catch(function (err0r) {
    //                 alert('webcam is not detected')
    //             });
    //     }

    // }




    knowmyidfun = () => {
        alert(this.state.myid)
    }
    render() {
        const theme = createMuiTheme({
            overrides: {
                MuiTooltip: {
                    tooltip: {
                        fontSize: "1.3em",
                    }
                }
            }
        });
        return (
            <div>
                <div className="header">

                    <div className="brand">


                    </div>

                    <img src={logo} className='logo-vero'></img>
                    <div style={{ display: 'flex', position: 'absolute', left: '17%' }}>
                        <i style={{fontSize: '23px', color: 'green' }} class='material-icons' >security</i>
                        <p style={{ fontSize: '7px', width: '100%' }}>Enhanced End to End Encryption</p>
                    </div>


                </div>
                <div style={{ position: 'absolute', top: '6vh', left: '40vw', color: 'white' }}>

                    <h4>Room name: {this.state.meetingroomname} ,   Host name: {this.state.hostname}</h4>


                </div>
                <div style={{marginTop: '250px',display:'flex',justifyContent:'center'}}>
                        <h4 style={{ color: 'red'}} >Please do not press back button or close/reload browser, to disconnect  </h4><a style={{marginTop:'14px'}} onClick={() => { this.dashboard() }}  >Click Here</a>
        
                        </div>
                
               
                <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', width: '100vw' }}>

                    <video id="receptionvideo" src={receptionVideo} autoPlay style={{ width: '50vw' }}></video>
                    <video id="checkCam" style={{ width: '50vw' }} autoPlay ></video>
                </div>
                <div style={{ position: 'absolute', top: '73vh', left: '40vw' }}>


                    <h2 id="waitingforhost" style={{ color: 'red', display: 'block' }}>Waiting for host</h2>
                    <h2 id="hosthasarrived" style={{ color: 'green', display: "none" }}>Host has arrived</h2>
                    <h5 style={{ color: 'green'}}>Establishing Secure Connection</h5>

                </div>

                <br></br>
            <i id="camCheck" style={{display:'none', cursor: 'pointer', padding: 2, color: 'blue', fontSize: '22px', backgroundColor: 'white', borderRadius: '20px', marginTop: '11px' }} class='material-icons'>videocam</i>
                        
                <ul className="bottomcontrols" id="bottomcontrolsid">
                    <MuiThemeProvider theme={theme}>
                        <Tooltip title="Attendee guide">
                            <img src={infoicon} style={{ width: '30px', cursor: 'pointer', marginTop: '11px' }} onClick={() => { this.showWaitingContent() }} ></img>

                        </Tooltip>
                    </MuiThemeProvider>
                    <MuiThemeProvider theme={theme}>
                        <Tooltip title="VEROHive Magazine"><li> <i id="VEROMagazine" style={{ cursor: 'pointer', padding: 2, color: 'blue', fontSize: '22px', backgroundColor: 'white', borderRadius: '20px', marginTop: '11px' }} class='material-icons' onClick={() => { console.log('no content') }} >sticky_note_2</i>
                        </li></Tooltip>
                    </MuiThemeProvider>

                  
                     <MuiThemeProvider theme={theme}>
                        <Tooltip title="CNA News"><li> <i id="CNANews" style={{ cursor: 'pointer', padding: 2, color: 'blue', fontSize: '22px', backgroundColor: 'white', borderRadius: '20px', marginTop: '11px' }} class='material-icons' onClick={() => { this.setState({ cnaNews: 'showme' }) }} >feed</i>
                        </li></Tooltip>
                    </MuiThemeProvider>
                    <MuiThemeProvider theme={theme}>
                        <Tooltip title="Travel"><li> <i id="Travel" style={{ cursor: 'pointer', padding: 2, color: 'blue', fontSize: '22px', backgroundColor: 'white', borderRadius: '20px', marginTop: '11px' }} class='material-icons' onClick={() => { this.setState({ Travel: 'showme' }) }} >airplane_ticket</i>
                        </li></Tooltip>
                    </MuiThemeProvider>
                    <MuiThemeProvider theme={theme}>
                        <Tooltip title="Breaking News"><li> <i id="finance" style={{ cursor: 'pointer', padding: 2, color: 'blue', fontSize: '22px', backgroundColor: 'white', borderRadius: '20px', marginTop: '11px' }} class='material-icons' onClick={() => { this.setState({ financeVideo: 'showme' }) }} >tv</i>
                        </li></Tooltip>
                    </MuiThemeProvider>
                    <MuiThemeProvider theme={theme}>
                        <Tooltip title="Upcoming Movies"><li> <i id="news" style={{ cursor: 'pointer', padding: 2, color: 'blue', fontSize: '22px', backgroundColor: 'white', borderRadius: '20px', marginTop: '11px' }} class='material-icons' onClick={() => { this.setState({ newsVideo: 'showme' }) }} >radio</i>
                        </li></Tooltip>
                    </MuiThemeProvider>
                    <MuiThemeProvider theme={theme}>
                        <Tooltip title="Music"><li> <i id="music" style={{ cursor: 'pointer', padding: 2, color: 'blue', fontSize: '22px', backgroundColor: 'white', borderRadius: '20px', marginTop: '11px' }} class='material-icons' onClick={() => { this.setState({ musicVideo: 'showme' }) }} >audiotrack</i>
                        </li></Tooltip>
                    </MuiThemeProvider>
                    {
                    this.state.isViaLink != "true" ? (
                        <MuiThemeProvider theme={theme}>
                        <Tooltip title="Back to Dashboard"><li> <i id="back" style={{ cursor: 'pointer', padding: 2, color: 'blue', fontSize: '22px', backgroundColor: 'white', borderRadius: '20px', marginTop: '11px' }} class='material-icons' onClick={() => { this.dashboard() }} >home</i>
                        </li></Tooltip>
                    </MuiThemeProvider>
                    ) : null
                }



                </ul>

                {
                    this.state.waitingContainer != "" ? (
                        <div  >

                            <div className="divforemoji" style={{ position: 'absolute', zIndex: 100, left: '-40px', top: '0', backgroundColor: 'black' }}>

                                <br></br>
                                <div className="wrapper">
                                    <span><button style={{ cursor: 'pointer', backgroundColor: "red", color: 'white', border: 'none' }}
                                        onClick={() => this.setState({
                                            waitingContainer: "",

                                        })}>X</button></span>
                                    <p style={{ fontWeight: 'bold', textAlign: 'center' }}> Welcome to the VEROHive Waiting Room, Please Be Patient While Your Host Prepares To Bring You into The Room.</p>



                                    <p> In the Meanwhile:</p>


                                    <p> 1) Make sure that your camera is positioned the way you want it to be positioned.</p>

                                    <p>2) Make sure that your lighting is set for the best presentation of You.</p>

                                    <p> 3) Learn more about VEROHive at www.verohive.com</p>


                                    <p> If you are a member of the VEROHive family please feel free to enjoy all the products that we offer to our members.<br></br>
                        If you are a guest attendee then we welcome you to VEROHive and feel free to become a member at any time.
                      </p>


                                    <p> Premium features such as logo and graphic overlays, video frame overlays for hosts, video intros for hosts, YouTube Live sharing and much more will be available soon.

                    </p>

                                    <p> Have a great meeting and thank you again for using VEROHive!
                    </p>
                                    <p>Notice: The host has the option of recording this meeting, once in the room please confirm if the meeting is being recorded or not.<br></br> If you opt not to be recorded then simply leave the room to avoid being recorded, otherwise it is established that you have given your consent..</p>

                                </div>


                            </div>



                        </div>
                    ) : null
                }
                {
                    this.state.financeVideo != "" ? (
                        <div  >

                            <div className="divforemoji" style={{ position: 'absolute', zIndex: 100, left: '-40px', top: '0', backgroundColor: 'black' }}>

                                <br></br>
                                <div className="wrapper" style={{ width: '625px', left: '26vw' }}>
                                    <span><button style={{ cursor: 'pointer', backgroundColor: "red", color: 'white', border: 'none' }}
                                        onClick={() => this.setState({
                                            financeVideo: "",

                                        })}>X</button></span>
                                    <h3>Breaking News</h3>
                                    <iframe width="560" height="315" src="https://www.youtube.com/embed/9Auq9mYxFEE" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>



                                </div>


                            </div>



                        </div>
                    ) : null
                }
                {
                    this.state.newsVideo != "" ? (
                        <div  >

                            <div className="divforemoji" style={{ position: 'absolute', zIndex: 100, left: '-40px', top: '0', backgroundColor: 'black' }}>

                                <br></br>
                                <div className="wrapper" style={{ width: '625px', left: '26vw' }}>
                                    <span><button style={{ cursor: 'pointer', backgroundColor: "red", color: 'white', border: 'none' }}
                                        onClick={() => this.setState({
                                            newsVideo: "",

                                        })}>X</button></span>
                                    <h3>Upcoming Movies</h3>
                                    <iframe width="560" height="315" src="https://www.youtube.com/embed/EHXqb-vACOQ" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

                                        </div>


                            </div>



                        </div>
                    ) : null
                }
                {
                    this.state.musicVideo != "" ? (
                        <div  >

                            <div className="divforemoji" style={{ position: 'absolute', zIndex: 100, left: '-40px', top: '0', backgroundColor: 'black' }}>

                                <br></br>
                                <div className="wrapper" style={{ width: '625px', left: '26vw' }}>
                                    <span><button style={{ cursor: 'pointer', backgroundColor: "red", color: 'white', border: 'none' }}
                                        onClick={() => this.setState({
                                            musicVideo: "",

                                        })}>X</button></span>
                                    <h3>Music</h3>
                                    <iframe width="560" height="315" src="https://www.youtube.com/embed/adLGHcj_fmA" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

                                </div>


                            </div>



                        </div>
                    ) : null
                }

{
                    this.state.cnaNews != "" ? (
                        <div  >

                            <div className="divforemoji" style={{ position: 'absolute', zIndex: 100, left: '-40px', top: '0', backgroundColor: 'black' }}>

                                <br></br>
                                <div className="wrapper" style={{ width: '625px', left: '26vw' }}>
                                    <span><button style={{ cursor: 'pointer', backgroundColor: "red", color: 'white', border: 'none' }}
                                        onClick={() => this.setState({
                                            cnaNews: "",

                                        })}>X</button></span>
                                    <h3>CNA News </h3>
                                    <iframe width="560" height="315" src="https://www.youtube.com/embed/XWq5kBlakcQ" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                </div>


                            </div>



                        </div>
                    ) : null
                }

{
                    this.state.Travel != "" ? (
                        <div  >

                            <div className="divforemoji" style={{ position: 'absolute', zIndex: 100, left: '-40px', top: '0', backgroundColor: 'black' }}>

                                <br></br>
                                <div className="wrapper" style={{ width: '625px', left: '26vw' }}>
                                    <span><button style={{ cursor: 'pointer', backgroundColor: "red", color: 'white', border: 'none' }}
                                        onClick={() => this.setState({
                                            Travel: "",

                                        })}>X</button></span>
                                    <h3>Travel </h3>
                                    <iframe width="560" height="315" src="https://www.youtube.com/embed/fUhZxP9s8x0" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                </div>


                            </div>



                        </div>
                    ) : null
                }
                <div id="roomSelection" style={{ display: 'block', position: 'absolute', left: '40%', top: '40%' }}>
                    <label>Enter Full Name :</label>
                    <input id="name" type="text" className="input-meet" />
                    <br></br><br></br>

                    <input id="room" type="text" className="input-meet" style={{ display: 'none' }} />
                    <br></br><br></br>
                    <button id="register" className="btn-meet">Enter</button>
                </div>

                {/* <div id="meetingRoom" style={{ display: 'none', marginTop: '60px' }}>
                   
                    <input id="videoinputfid" type="text" style={{ display: 'none' }} />
                                  
                </div> */}



            </div>
        )
    }
}

export default WaitingRoom