import React, { Component } from "react";

import logo from "./verohivelogo.png";
import gmail from "./gmail.png";
import a from "./verifybadges/a.png";
import b from "./verifybadges/b.png";
import g from "./verifybadges/g.png";
import p from "./verifybadges/p.png";
import r from "./verifybadges/r.png";
import y from "./verifybadges/y.png";
import verogo from "./imgs/verogo.png";
import userpic from "./verifybadges/user.png";
import * as Api from "./api";
import Linkify from "react-linkify";
import Toggle from "react-toggle";
import Privacypolicy from "./privacypolicy";
import copyrightlogo from "./imgs/CopyrightVERO.png";
import infoicon from "./imgs/infoicon.png";
import kurentoUtils from "kurento-utils";
import axios from "axios";
import {
  createMuiTheme,
  MuiThemeProvider,
  withStyles,
} from "@material-ui/core/styles";
import { Tooltip } from "@material-ui/core";
import {
  FacebookShareCount,
  FacebookIcon,
  FacebookShareButton,
  TwitterIcon,
  TwitterShareButton,
  TelegramIcon,
  WhatsappIcon,
  WhatsappShareButton,
  LineShareButton,
  LinkedinIcon,
  PinterestIcon,
  PinterestShareButton,
  TelegramShareButton,
  LinkedinShareButton,
} from "react-share";
import { Overlay } from "react-portal-overlay";
import TermsCondition from "./TermsCondition";
import { Socket } from "socket.io-client";
import io from "socket.io-client";
import Membership from "./Membership";

var socket = io();
let currentStream;
class Home extends Component {
  videoEle = React.createRef();
  selectEle = React.createRef();
  state = {
    username: "",
    id: "",
    privatekey: "",
    firstname: "",
    lastname: "",
    email: "",
    organization: "",
    bio: "",
    city: "",
    country: "",
    links: "",
    key: "",
    baconIsReady: false,
    followuser: [],
    write: "",
    following: [],
    invitenewuser: "",
    mailsentsuccessfully: "",
    SenderName: "",
    enterroomname: "",
    enterroomid: "",
    open: false,
    joinhost_code: "",
    infobox: "",
    infoboxcontainer: "",
    Twitter: "",
    roompin: "",
    youarenothost: "",
    facingMode: "environment",
    facingModeButtonText: "Back Camera",
    isElectronNotifier: false,
    membershipLevel: "",
    showMembership: false,
    devices: [],
    camMode: ""
  };

  componentDidMount() {
    const isElectron = () => {
      // Renderer process
      if (
        typeof window !== "undefined" &&
        typeof window.process === "object" &&
        window.process.type === "renderer"
      ) {
        // document.getElementById('host-room-id').style.display = 'flex';
        // document.getElementById('create-a-new-room').style.display = 'flex';
        this.setState({
          isElectronNotifier: true,
        });
      }

      // Main process
      if (
        typeof process !== "undefined" &&
        typeof process.versions === "object" &&
        !!process.versions.electron
      ) {
        // document.getElementById('host-room-id').style.display = 'flex';
        // document.getElementById('create-a-new-room').style.display = 'flex';
        this.setState({
          isElectronNotifier: true,
        });
      }

      // Detect the user agent when the `nodeIntegration` option is set to true
      if (
        typeof navigator === "object" &&
        typeof navigator.userAgent === "string" &&
        navigator.userAgent.indexOf("Electron") >= 0
      ) {
        // document.getElementById('host-room-id').style.display = 'flex';
        // document.getElementById('create-a-new-room').style.display = 'flex';
        this.setState({
          isElectronNotifier: true,
        });
      }

      // document.getElementById('host-room-id').style.display = 'flex';
      // document.getElementById('create-a-new-room').style.display = 'flex';
    };
    isElectron();

    navigator.mediaDevices.enumerateDevices().then((mediaDevices) => {
      let devices = mediaDevices;
      devices = devices.filter((device) => {
        return device.kind === "videoinput";
      });
      if (devices.length) {
        console.log("Devices:", devices);
        this.setState({
          devices: devices,
        });
        if (devices.length > 0) {
          this.setState({
            camMode: devices[0].deviceId,
          });
          this.startCam(devices[0].deviceId);
        }
      } else {
        console.log("Cannot access to mediaSources!");
      }
    });
    // document.onkeydown = function (e) {
    //   if (e.keyCode == 123) {
    //     return false;
    //   }
    //   if (e.ctrlKey && e.shiftKey && e.keyCode == 'I'.charCodeAt(0)) {
    //     return false;
    //   }
    //   if (e.ctrlKey && e.shiftKey && e.keyCode == 'J'.charCodeAt(0)) {
    //     return false;
    //   }
    //   if (e.ctrlKey && e.keyCode == 'U'.charCodeAt(0)) {
    //     return false;
    //   }
    // }

    // const {username}=this.props;

 
    console.log("dd", this.props.location.state.username);
    var username = this.props.location.state.username;
    this.setState({
      id: this.props.location.state.username,
    });

    fetch("/getuser", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        username,
      }),
    })
      .then((response) => response.json())
      .then((res) => {
        console.log("ff", res);
        this.setState({
          privatekey: res.privateKey,
          firstname: res.firstName,
          lastname: res.lastName,
          email: res.email,
          organization: res.organization,
          verified: res.verified,
          bio: res.bio,
          city: res.city,
          country: res.country,
          links: res.links,
          image1: res.ProfilePic,
          Twitter: res.Twitter,
          roompin: res.roompin,
        });
        const emailforStatus = res.email;
        axios
          .get(
            `https://megahoot.org/mh_api_check_email.php?email=${emailforStatus}`
          )
          .then((res) => {
            if(res.data.member_level){
              if(res.data.member_level=='Member'){
                let statusData=res.data.member_level
                this.setState({
                  membershipLevel: statusData
                });
              }else{
                let statusData=res.data.member_level +" "+"Member"
                this.setState({
                  membershipLevel: statusData
                });
              }
            
            }else{
              this.setState({
                membershipLevel: "Guest Member"
              });
            }
           
          });
      })
      .catch((err) => console.log(err));




      window.onbeforeunload = ()=> {
       
      window.setTimeout(()=> { 
        this.props.history.push("/private", {
          username: this.state.id,
        });
      }, 0); 
      window.onbeforeunload = null; // necessary to prevent infinite loop, that kills your browser 
  }
  }

  stopCam = () => {
    console.log("Stop Camera");
    if (this.isRunning()) {
      const stream = this.videoEle.current.srcObject;
      const tracks = stream.getTracks();
      tracks.forEach((track) => {
        track.stop();
      });
    }
  };

  isRunning = () => {
    const stream = this.videoEle.current.srcObject;
    return stream && stream.getTracks;
  };

  startCam = async (deviceId) => {
    console.log("Start Camera");
    try {
      if (typeof currentStream !== "undefined") {
        this.stopCam(currentStream);
      }

      const videoConstraints = {};
      if (this.state.camMode === "") {
        videoConstraints.facingMode = "environment";
        videoConstraints.width = { min: 640, ideal: 1920, max: 1920 };
        videoConstraints.height = { min: 400, ideal: 1080 };
        videoConstraints.aspectRatio = 1.777777778;
        videoConstraints.frameRate = { max: 30 };
      } else {
        videoConstraints.deviceId = { exact: deviceId };
        videoConstraints.width = { min: 640, ideal: 1920, max: 1920 };
        videoConstraints.height = { min: 400, ideal: 1080 };
        videoConstraints.aspectRatio = 1.777777778;
        videoConstraints.frameRate = { max: 30 };
      }

      const constraints = {
        video: videoConstraints,

        audio: false,
      };

      const stream = await navigator.mediaDevices.getUserMedia(constraints);

      currentStream = stream;
      this.videoEle.current.srcObject = stream;
    } catch (err) {
      console.log(err);
    }
  };
  changeSource = (e) => {
    console.log("Change to:", e.target.value);
    this.setState({
      camMode: e.target.value,
    });
    if (this.isRunning()) {
      this.stopCam();
      this.startCam(e.target.value);
    }
  };


  // sendemail = (enteredemail) => {
  //   console.log("rjha", enteredemail)
  //   // const enteredemail = prompt("Enter email to invite ")
  //   // const enteredemail = this.state.emailinvite
  //   // navigator.clipboard.writeText(this.props.location.state.room_code);
  //   fetch("/nodemailer", {
  //     method: "post",
  //     headers: {
  //       "Content-Type": "application/json"
  //     },
  //     body: JSON.stringify({
  //       To: enteredemail,
  //       subject: "VEROHive Meeting Invitation" + this.state.write,
  //       text: "Your invitation Code is" + this.state.write,
  //       html: `<img src="./verohivelogo.png" style="width: 30%;">
  //       <h1 style="color: #5b5b5b;">VEROHive Meeting Invitation</h1>
  //       <h3 style="color: #757575;">Click the link below to respond to the invitation.</h3>

  //     <br>

  //       <h4 style="color: #757575;">Cheers!</h4>
  //       <h4 style="color: #757575;">VEROHive Team</h4>
  //       `

  //     })
  //   })

  //     .then(() => {

  //       console.log("ddd")
  //       //  this.verify()
  //       this.setState({ show: false })
  //       alert("Mail sent successfully")
  //     })
  //     .catch(err => console.log(err))
  //skytest

  // }

  openclose = () =>
    this.setState({
      show: true,
    });

  newinvite = () => {
    console.log("dd", this.props.location.state.username);
    var username = this.props.location.state.username;
    this.setState({
      id: this.props.location.state.username,
    });
    // console.log("rjha", enteredemail)
    // const enteredemail = prompt("Enter email to invite ")
    const shareemail = this.state.invitenewuser;
    // const userown = this.state.username
    // navigator.clipboard.writeText(this.props.location.state.room_code);
    fetch("/getuser", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        username,
      }),
    })
      .then((response) => response.json())
      .then((res) => {
        console.log("ff", res);
        this.setState({
          privatekey: res.privateKey,
          firstname: res.firstName,
          lastname: res.lastName,
        });
      })
      .catch((err) => console.log(err));

    fetch("/nodemailer", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },

      body: JSON.stringify({
        To: shareemail,
        subject: "VEROHive Member Invitation",
        text:
          "VEROHive Invitation From" +
          this.state.firstname +
          "" +
          this.state.lastname,
        html: `<a href="https://verohive.net/"><img src="https://farma-consumer.s3.ap-south-1.amazonaws.com/c230d91721664885cf6c0cf1b1c6c70f.png" style="width: 20%;"></a>
        
        <h4>${
          this.state.firstname + " " + this.state.lastname
        } is sharing their VERO Number with you so that you can connect on the secure video conferencing and collaboration platform VEROHive.</h4>
         <h4>Their VERO Number is:</h4>
        <h2>${this.state.privatekey}</h2>
        <p style="color: black;">
        <br>
        Just log into your VEROHive account and click on the contacts link that can be found in the side panel, paste their VERO Number in the Enter VERO Number slot and connect.
        <br><br>
        If you are not a member yet, you can click here and Register for FREE.  <a href="https://verohive.net/register">Signup Now</a>
       <br><br>
       VEROHive,  it's not working from home, it's working from anywhere!
        <br>
        <br>
    
        </p>
        
       
     
       
  
      <br>
      
       
        
        
        <h4 style="color: #757575;">Cheers!</h4>
        <h4 style="color: #757575;">The VEROHive Team!</h4>
        `,
      }),
    })
      .then(() => {
        console.log("ddd");
        //  this.verify()
        this.setState({ invitenewuser: "" });
        this.setState({ SenderName: "" });
        this.setState({ mailsentsuccessfully: "Mail sent successfully" });
        // alert("Mail sent successfully")
      })
      .catch((err) => console.log(err));
  };
  //invitenewuser
  handleChange(event) {
    // do something with event.target.checked
    this.setState({
      baconIsReady: event.target.checked,
    });
  }
  contact = () => {
    this.props.history.push("/contact", {
      username: this.state.id,
    });
  };
  miniapp = () => {
    this.props.history.push("/Miniapp", {
      username: "guest",
    });
  };
  meetingmediaserver = () => {
    this.props.history.push("/meeting");
  };
  TermsCondition = () => {
    this.props.history.push("/TermsCondition", {
      username: this.state.id,
    });
  };

  meetingScheduler = () => {
    this.props.history.push("/meetingScheduler", {
      username: this.state.id,
    });
  };

  Membership = () => {
    this.props.history.push("/Membership", {
      email: this.state.email,
    });
  };

  sayHello1 = () => {
    this.props.history.push("/profile", {
      username: this.state.id,
    });
  };
  publicProfile = () => {
    this.props.history.push("/publicProfile", {
      username: this.state.id,
    });
  };

  Messanger = () => {
    this.props.history.push("/chat", {
      username: this.state.id,
    });
  };

  openModal = () => {
    this.setState({
      open: true,
    });
  };

  sayHello = () => {
    console.log("Hello");
    fetch("/logout", {
      method: "get",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        localStorage.removeItem("user");
        this.props.history.push("/login");
        window.location.reload();
        console.log("ff", res);
      })
      .catch((err) => console.log(err));
  };
  renderLoading() {
    return <div>Logging in...</div>;
  }
  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  infohostjoin = () => {
    this.setState({
      infoboxcontainer: "showme",
      infobox:
        "If you scheduled a Vero Meeting then you can add the Room ID associated with that meeting here and press the green arrow so you can join as the Vero Host.",
    });
  };
  infohostcreate = () => {
    this.setState({
      infoboxcontainer: "showme",
      infobox:
        "Here is where you can create a Vero Room at any time, just pick a name for your room and you will automatically enter the room once you press the green arrow, once in the room you can invite others to the Vero Meeting.",
    });
  };

  infoattendeejoin = () => {
    this.setState({
      infoboxcontainer: "showme",
      infobox:
        "If you received an invitation via email then this is where you enter that Room ID and press the green arrow. You will be sent to a waiting room until the host brings you into the Vero Meeting.",
    });
  };

  joinRoom = () => {
    function detectWebcam(callback) {
      let md = navigator.mediaDevices;
      if (!md || !md.enumerateDevices) return callback(false);
      md.enumerateDevices().then((devices) => {
        callback(devices.some((device) => "videoinput" === device.kind));
      });
    }

    detectWebcam(function (hasWebcam) {
      if (!hasWebcam) {
        return this.setState({ enterroomid: "Your Camera is not connected" });
      }
    });
    console.log("hello");
    if (!this.state.room_code) {
      return this.setState({ enterroomid: "Enter the room id" });
      // alert("Enter the room id");
    }

    // API.get('/rooms/join/'+this.state.room_code, {

    // }).then((res) => {
    // 	console.log("dd",res)
    // 	// this.props.flashHandler('success', 'Room Joined!');
    // 	this.setState({ room_name: res.data.data.room_name, type: 'client' })

    this.props.history.push({
      pathname: "/waitingRoom",
      search:
        "?" + new URLSearchParams({ id: this.state.room_code }).toString(),

      state: {
        type: "client",
        room_code: this.state.room_code,
        username: this.state.id,
        privatekey: this.state.privatekey,
        email: this.state.email,
        firstname: this.state.firstname,
        lastName: this.state.lastname,
        organization: this.state.organization,
        camMode:this.state.camMode
      },
    });
    // }).catch((error) => {
    // 	this.props.flashHandler('error', 'Room is full!');
    // });
  };

  joinhostnow = () => {
    console.log(this.state.joinhost_code);
    var scheduledroomcode = this.state.joinhost_code;
    var firstsixdigit = scheduledroomcode.toString();
    if (this.state.roompin == firstsixdigit.substring(0, 6)) {
      this.props.history.push({
        pathname: "/videochat",
        search:
          "?" +
          new URLSearchParams({ id: this.state.joinhost_code }).toString(),
        state: {
          type: "host",
          room_code: this.state.joinhost_code,
          // room_name:this.state.room_name,
          username: this.state.id,
          privatekey: this.state.privatekey,
          email: this.state.email,
          firstname: this.state.firstname,
          lastName: this.state.lastname,
          organization: this.state.organization,
          camMode:this.state.camMode
        },
      });
    } else {
      this.setState({
        youarenothost: "you are not the host of this room",
      });
    }
  };
  createRoom = () => {
    // console.log('hello')
    function detectWebcam(callback) {
      let md = navigator.mediaDevices;
      if (!md || !md.enumerateDevices) return callback(false);
      md.enumerateDevices().then((devices) => {
        callback(devices.some((device) => "videoinput" === device.kind));
      });
    }

    detectWebcam(function (hasWebcam) {
      if (!hasWebcam) {
        return this.setState({ enterroomid: "Your Camera is not connected" });
      }
    });
    if (!this.state.room_name) {
      return this.setState({ enterroomname: "Enter the room name" });
      // alert("Enter the room name");
    }

    var rand, mailOptions, host, link;
    var rand1 = Math.floor(Math.random() * 100 + 54);
    var rand2 = Math.floor(Math.random() * 100 + 54);
    var rand3 = Math.floor(Math.random() * 100 + 54);
    var rand4 = Math.floor(Math.random() * 100 + 54);
    var rand5 = Math.floor(Math.random() * 100 + 54);

    rand =
      rand1.toString() +
      rand2.toString() +
      rand3.toString() +
      rand4.toString() +
      rand5.toString();

    // rand = rand1.toString()+ Math.random().toString(36) +rand2.toString()+ Math.random().toString(36);

    // if (this.state.room_name == '') {
    //   this.props.flashHandler('error', "You didn't enter a room name!");
    //   return;
    // }
    // var rand, mailOptions, host, link;
    // var rand1 = Math.floor((Math.random() * 100) + 54);
    // var rand2 = Math.floor((Math.random() * 100) + 54);
    // var rand3 = Math.floor((Math.random() * 100) + 54);
    // rand = rand1.toString() + rand2.toString() + rand3.toString();
    // let userData = JSON.parse(localStorage.getItem('userData'));
    // let userID = userData.id;

    // API.post('/rooms/create', {
    //   name: this.state.room_name,
    //   creatorId: userID
    // }).then((res) => {
    //   console.log(res.data.data.roomCode);
    //   this.setState({
    //     type: 'host',
    //     room_code: res.data.data.roomCode
    //   });
    //Should get room code here and set its state
    // this.props.flashHandler('success', 'Room Joined!');

    this.props.history.push({
      pathname: "/videochat",
      search: "?" + new URLSearchParams({ id: rand }).toString(),
      state: {
        type: "host",
        room_code: rand,
        room_name: this.state.room_name,
        username: this.state.id,
        privatekey: this.state.privatekey,
        email: this.state.email,
        firstname: this.state.firstname,
        lastName: this.state.lastname,
        organization: this.state.organization,
        camMode:this.state.camMode
      },
    });
  };
  inputHandler = (event) => {
    let field = event.target.name;
    let value = event.target.value;
    this.setState({
      [field]: value.trim(),
    });
  };
  // viewfollow = () => {
  //   const { email, privatekey, key } = this.state;
  //   fetch("/follow/followrequests", {
  //     method: "post",
  //     headers: {
  //       "Content-Type": "application/json"
  //     },
  //     body: JSON.stringify({
  //       privatekey
  //     })
  //   })
  //     .then(response => response.json())
  //     .then((res) => {
  //       console.log("Dd", res)
  //       this.setState({ followuser: res })
  //     })
  //     .catch(err => console.log(err))
  // }

  // acceptrequest = (email) => {
  //   const { firstname, lastname } = this.state;
  //   const fullnameaccepted = firstname + lastname
  //   const emailaccepted = this.state.email
  //   fetch("/follow/acceptrequests", {
  //     method: "post",
  //     headers: {
  //       "Content-Type": "application/json"
  //     },
  //     body: JSON.stringify({
  //       email,
  //       emailaccepted,
  //       fullnameaccepted
  //     })
  //   })
  //     .then((res) => {
  //       console.log("Dd", res)
  //       alert("connected successfully")
  //       this.viewfollow()
  //       this.viewfollowing()
  //     })
  //     .catch(err => console.log(err))
  // }
  // follow = () => {
  //   const { email, privatekey, key, firstname, lastname } = this.state;
  //   var fullname = firstname + lastname
  //   if (key == privatekey) {
  //     alert("You can't follow yourself")

  //   }
  //   else {
  //     fetch("/follow", {
  //       method: "post",
  //       headers: {
  //         "Content-Type": "application/json"
  //       },
  //       body: JSON.stringify({

  //         email,
  //         privatekey,
  //         fullname,
  //         key
  //       })
  //     })
  //       .then((res) => {
  //         console.log("rohan", res)
  //         if (res.status == 401) {
  //           alert("already connected")
  //         }
  //         else {
  //           alert("Follow request sent successfully")
  //         }

  //       })
  //       .catch(err => console.log(err))
  //   }

  // }
  // viewfollowing = () => {
  //   this.setState({ following: [] })
  //   const privatekey = this.state.privatekey
  //   fetch("/follow/following", {
  //     method: "post",
  //     headers: {
  //       "Content-Type": "application/json"
  //     },
  //     body: JSON.stringify({
  //       privatekey
  //     })
  //   })
  //     .then(response => response.json())
  //     .then((res) => {
  //       console.log("Dd", res)
  //       this.setState({ following: res })
  //     })
  //     .catch(err => console.log(err))
  //   fetch("/follow/followinga", {
  //     method: "post",
  //     headers: {
  //       "Content-Type": "application/json"
  //     },
  //     body: JSON.stringify({
  //       privatekey
  //     })
  //   })
  //     .then(response => response.json())
  //     .then((res) => {
  //       console.log("Dd", res)
  //       if (this.state.following.length == undefined) {
  //         this.setState({ following: res })
  //       }
  //       else {
  //         console.log("here")
  //         for (var i = 0; i < res.length; i++) {
  //           this.state.following.push(res[i])
  //         }

  //         this.setState({ following: this.state.following })
  //       }

  //     })
  //     .catch(err => console.log(err))
  // }
  // Upload1_To_AWS_S3 = () => {
  //   var that = this;
  //   const { email } = this.state;
  //   // console.log("Dddd", this.state.image)
  //   let formData = new FormData();
  //   formData.append("photo", this.state.image);
  //   try {
  //     const res = Api.uploadImageToAwsS3(formData)
  //     res.then(function (value) {
  //       // console.log(value); // "Success"
  //       // that.setState({
  //       //   image: value
  //       // })
  //       fetch("/updateProfilePic", {
  //         method: "post",
  //         headers: {
  //           "Content-Type": "application/json"
  //         },
  //         body: JSON.stringify({
  //           email,
  //           value

  //         })
  //       })

  //         .then(() => {

  //           alert("profile pic changed successfully")
  //           that.setState({
  //             image1: value
  //           })

  //         })
  //         .catch(err => console.log(err))
  //     })

  //   } catch (e) {

  //   }

  // }

  // async onSubmit(e) {
  //   e.preventDefault();
  //   const { username } = this.state;

  //   this.props.history.push({
  //     pathname: '/videochat',
  //     search: "?" + new URLSearchParams({ id: username }).toString(),
  //     state: { username: this.state.id }
  //   })

  // }

  render() {
    const openMenu = () => {
      document.querySelector(".sidebar").classList.add("open");
    };

    const closeMenu = () => {
      document.querySelector(".sidebar").classList.remove("open");
    };

    const opencontact = () => {
      document.querySelector(".contactbar").classList.add("open");
    };

    const closecontact = () => {
      document.querySelector(".contactbar").classList.remove("open");
    };
    const shareUrl = "https://verohive.net/register";
    const title = "Vero Dealroom";

    const componentDecorator = (href, text, key) => (
      <a href={href} key={key} target="_blank" rel="noopener noreferrer">
        {text}
      </a>
    );

    const theme = createMuiTheme({
      overrides: {
        MuiTooltip: {
          tooltip: {
            fontSize: "1em",
          },
        },
      },
    });

    return (
      <div className="home-main">
        <div className="header">
          <div className="brand">
            <button onClick={openMenu}>&#9776;</button>
          </div>
          <img src={logo} className="logo-vero"></img>
          <h4 style={{ color: "white", marginRight: "45%" }}>
          V4.1.1
          </h4>
        </div>

        {this.state.enterroomid != "" ? (
          <div
            style={{
              zIndex: "10000000",
              backgroundColor: "white",
              padding: "10px",
              color: "grey",
              fontSize: "2.4rem",
              position: "absolute",
              top: "30%",
              left: "40%",
              width: "300px",
              height: "200px",
            }}
          >
            <br></br>
            <br></br>{" "}
            <span style={{ marginTop: "100px" }}>
              {" "}
              {this.state.enterroomid}
              <br></br>
            </span>
            <span>
              <button
                style={{
                  backgroundColor: "#4FADD3",
                  color: "white",
                  border: "none",
                  width: "100px",
                }}
                onClick={() =>
                  this.setState({
                    enterroomid: "",
                  })
                }
              >
                OK
              </button>
            </span>
          </div>
        ) : null}

        {this.state.youarenothost != "" ? (
          <div
            style={{
              zIndex: "10000000",
              backgroundColor: "white",
              padding: "10px",
              color: "grey",
              fontSize: "2.4rem",
              position: "absolute",
              top: "30%",
              left: "40%",
              width: "300px",
              height: "200px",
            }}
          >
            <br></br>
            <br></br>{" "}
            <span style={{ marginTop: "100px" }}>
              {" "}
              {this.state.youarenothost}
              <br></br>
            </span>
            <span>
              <button
                style={{
                  backgroundColor: "#4FADD3",
                  color: "white",
                  border: "none",
                  width: "100px",
                }}
                onClick={() =>
                  this.setState({
                    youarenothost: "",
                  })
                }
              >
                OK
              </button>
            </span>
          </div>
        ) : null}

        {this.state.mailsentsuccessfully != "" ? (
          <div
            style={{
              zIndex: "10000000",
              backgroundColor: "white",
              padding: "10px",
              color: "grey",
              fontSize: "2.4rem",
              position: "absolute",
              top: "30%",
              left: "40%",
              width: "300px",
              height: "200px",
            }}
          >
            <br></br>
            <br></br>{" "}
            <span style={{ marginTop: "100px" }}>
              {" "}
              {this.state.mailsentsuccessfully}
              <br></br>
            </span>
            <span>
              <button
                style={{
                  backgroundColor: "#4FADD3",
                  color: "white",
                  border: "none",
                  width: "100px",
                }}
                onClick={() =>
                  this.setState({
                    mailsentsuccessfully: "",
                  })
                }
              >
                OK
              </button>
            </span>
          </div>
        ) : null}
        {this.state.enterroomname != "" ? (
          <div
            style={{
              zIndex: "10000000",
              backgroundColor: "white",
              padding: "10px",
              color: "grey",
              fontSize: "2.4rem",
              position: "absolute",
              top: "30%",
              left: "40%",
              width: "300px",
              height: "200px",
            }}
          >
            <br></br>
            <br></br>{" "}
            <span style={{ marginTop: "100px" }}>
              {" "}
              {this.state.enterroomname}
              <br></br>
            </span>
            <span>
              <button
                style={{
                  backgroundColor: "#4FADD3",
                  color: "white",
                  border: "none",
                  width: "100px",
                }}
                onClick={() =>
                  this.setState({
                    enterroomname: "",
                  })
                }
              >
                OK
              </button>
            </span>
          </div>
        ) : null}
        {this.state.infoboxcontainer != "" ? (
          <div
            className="wrapper"
            style={{
              zIndex: "100000",
              width: "30vw",
              minWidth: "300px",
              top: "44vh",
              left: "35vw",
            }}
          >
            {this.state.infobox}

            <span>
              <button
                style={{
                  backgroundColor: "#4FADD3",
                  color: "white",
                  border: "none",
                  width: "100px",
                }}
                onClick={() =>
                  this.setState({
                    infoboxcontainer: "",
                  })
                }
              >
                close
              </button>
            </span>
          </div>
        ) : null}

        <aside className="sidebar">
          <h4 className="welcome-user">
            <div
              style={{
                width: "50px",
                height: "45px",
                backgroundColor: "#034063",
                marginLeft: "100px",
              }}
            >
              {this.state.image1 ? (
                <img
                  src={this.state.image1}
                  style={{
                    width: "50px",
                    height: "45px",
                    borderRadius: "64px",
                  }}
                />
              ) : (
                <div></div>
              )}
            </div>
            Welcome {this.state.id}{" "}
          </h4>
          <button className="" onClick={() => this.sayHello()}>
            Logout
          </button>
          <div
            style={{
              position: "absolute",
              right: "10px",
              top: "60px",
              zIndex: "900000",
            }}
          >
            {/* <i  class='material-icons'style={{marginLeft:'35px'}} >share</i><br></br><br></br> */}
            <i
              onDoubleClick={() => this.setState({ show: false })}
              onClick={() =>
                this.setState({
                  show: true,
                })
              }
              className="material-icons"
              style={{ marginLeft: "35px", cursor: "pointer" }}
            >
              share
            </i>
            {this.state.show ? <div></div> : null}
          </div>
          <button onClick={closeMenu} className="sidebar-close-btn">
            X
          </button>

          <hr></hr>
          <ul className="sidebar-ui">
            <li>
              <a className=" btn-profile ">Dashboard</a>
            </li>
         
            {/* <li ><a className=" btn-profile " href="https://screenshare.verohive.org/" target="_blank">Screenshare</a></li> */}

            {/* <li><a href="https://recordmeeting.verohive.org/" target="_blank"
              className=" btn-profile "
            >Recording</a></li> */}
            <li>
              <a className=" btn-profile " onClick={() => this.sayHello1()}>
                Update Profile
              </a>
            </li>

            <li>
              <a className=" btn-profile " onClick={() => this.publicProfile()}>
                Public Profile
              </a>
            </li>

            <li>
              <a className=" btn-profile ">Background</a>
            </li>
            <li>
              <a className=" btn-profile " onClick={() => this.contact()}>
                Contacts
              </a>
            </li>
            {/* <li><a
              className=" btn-profile "
              onClick={() => this.miniapp()}>miniapp</a></li> */}

            {/* <li><a
              className=" btn-profile "
              onClick={() => this.meetingmediaserver()}>Meeting room</a></li> */}
            <li>
              <a
                className=" btn-profile "
                onClick={() => {
                  this.meetingScheduler();
                }}
              >
                Schedule Meeting
              </a>
            </li>
            <li>
              <a
              href={`https://megahoot.org/upgrade/?source=PO39IDH98023FJNIOEDPFJ290U846H3U58H93HFE9PDSNIPDONF092H74U803H6T57-056I4KMH89T64J39JE28HE8722GD76F23VBF8B42I3NYVB89032UNV894-HNG983HE7DHC982H2HFH943Y6JEDSG87DGCS8NCV3R89256784Y6NVY729WE45161YDSAVYU-32Y78DC92378Y4D7892YJ489728&e=${this.state.email}&string=FD7EFWF89292H32JD092T8934H913BHD1GD8Y91BD91U2N9F8H4389FG3HJ0J01JH8D902HJ10DHJ213GD1789GB94TH5Y9G8YURVN8928923H98C4`}
              
                className="btn-profile "
                target="_blank"
              >
              Upgrade Membership
              </a>
            </li>
          </ul>

          <hr></hr>

          {/* <div style={{ fontSize: '1.4rem' }}>
            Create a Room
            <div style={{ display: 'flex', justifyContent: 'center' }} >
              <input className="checkmeet" onChange={(event) => { this.inputHandler(event) }} type="text" name="room_name" placeholder="Enter Room Name" />
              <i style={{ backgroundColor: 'green', color: 'white', borderRadius: '20px', cursor: 'pointer' }} onClick={() => { this.createRoom() }} className='material-icons'>done</i>
            </div>
            <br></br>
            Join a Room
            <div style={{ display: 'flex', justifyContent: 'center' }} >
              <input className="checkmeet" onChange={(event) => { this.inputHandler(event) }} type="text" name="room_code" placeholder="Enter Room code (e.g RG34WEZ)" />
              <i style={{ backgroundColor: 'green', color: 'white', borderRadius: '20px', cursor: 'pointer' }} onClick={() => { this.joinRoom() }} className='material-icons' >done</i>

            </div>
          </div> */}
        </aside>

        <div></div>

        {this.state.showMembership ? (
          <Membership
            email={this.state.email}
            closeMembershipCard={() => {
              this.setState({ showMembership: false });
            }}
          />
        ) : null}

        <div className="profile-container">
          <ul>
            <div className="profile-pic">
              <div>
                <div style={{ marginTop: "15%", marginLeft: "15%" }}>
                  {/* <img src={userpic} style={{ width: "100px", height: "90px" }} /> */}
                  {this.state.image1 ? (
                    <img
                      src={this.state.image1}
                      style={{
                        width: "130px",
                        height: "120px",
                        borderRadius: "64px",
                      }}
                    />
                  ) : (
                    <div></div>
                  )}
                  {/* {this.state.image1 ? (<button
                    className="btn btn-sendfile" style={{ backgroundColor: "red" }}
                    onClick={() => this.setState(
                      {
                        image1: null
                      }
                    )}>Change</button>) : null} */}
                </div>
              </div>

              <ul style={{ marginTop: "-6px" }}>
                <h2>
                  <li>
                    {this.state.firstname} {this.state.lastname}
                    {this.state.verified == "b" ? (
                      <img style={{ width: "25px" }} src={b}></img>
                    ) : null}
                    {this.state.verified == "a" ? (
                      <img style={{ width: "25px" }} src={a}></img>
                    ) : null}
                    {this.state.verified == "g" ? (
                      <img style={{ width: "25px" }} src={g}></img>
                    ) : null}
                    {this.state.verified == "p" ? (
                      <img style={{ width: "25px" }} src={p}></img>
                    ) : null}
                    {this.state.verified == "r" ? (
                      <img style={{ width: "25px" }} src={r}></img>
                    ) : null}
                    {this.state.verified == "y" ? (
                      <img style={{ width: "25px" }} src={y}></img>
                    ) : null}
                  </li>
                </h2>
                <ul
                  style={{
                    marginTop: "-15px",
                    marginLeft: "-30px",
                    textAlign: "start",
                  }}
                >
                  <li>Username:{this.state.id}</li>
                  <li>VERO Number: {this.state.privatekey}</li>
                  <li>Organization: {this.state.organization}</li>

                  <li>Country: {this.state.country}</li>
                  <li>City: {this.state.city}</li>
                  {/* <li>Bio: {this.state.bio}</li> */}
                </ul>
              </ul>
              <video
                style={{
                  width: "200px",
                  marginLeft: "auto",
                  borderRadius: "10px",
                }}
                id="checkCam"
                autoPlay
                ref={this.videoEle}
               poster="https://mhiservers.com/uO0muCuiv37bWZ0iiaPfsWUheuH6tGSgAssets/aB17BMXtfrcywe6Jh0RUMcN4dARoM6kyimages/WKjKIwwT%7Dj;tTcp4&tG9*eNC]gIElbsystem/VeroDealRoom_p.jpg"
      
              ></video>
            </div>

            <div className="profile-description">
              <ul>
                <li>Twitter :{this.state.Twitter}</li>
                <li>Email :{this.state.email}</li>
                {/* <Linkify componentDecorator={componentDecorator}>  <li>Links: {this.state.links} </li></Linkify> */}
                <li>Member Level : {this.state.membershipLevel}</li>
                <li>Camera: <select onChange={this.changeSource}>
            {this.state.devices.length > 0
              ? this.state.devices.map((device, index) => {
                  return (
                    <option key={index} value={device.deviceId}>
                      {device.label || "Camera " + (index + 1)}
                    </option>
                  );
                })
              : null}
          </select></li>
              </ul>
            </div>

            <form
              onSubmit={(e) => {
                e.preventDefault();
              }}
            >
              <div style={{ background: "#00000014" }}>
                <br></br>
                {this.state.membershipLevel=="Society Member" || this.state.membershipLevel=="Executive Member" || this.state.email=="maxakash1999@gmail.com"  ? (
                  <div>
                    {" "}
                    <div
                      style={{ display: "flex", justifyContent: "center" }}
                      id="host-room-id"
                    >
                      <p
                        style={{
                          width: "23%",
                          fontSize: "11px",
                          fontWeight: "bold",
                        }}
                      >
                        Host Room ID
                      </p>
                      <MuiThemeProvider theme={theme}>
                        <Tooltip title="HOST INFO">
                          <li style={{ fontStyle: "none" }}>
                            {/* <img src={infoicon} style={{ width: '30px' }} onClick={() => { this.infohostjoin() }} ></img> */}
                            <button
                              onClick={() => {
                                this.infohostjoin();
                              }}
                              className="infoIcon"
                            >
                              ?
                            </button>
                          </li>
                        </Tooltip>
                      </MuiThemeProvider>

                      <input
                        className="checkmeet"
                        onChange={(event) => {
                          this.inputHandler(event);
                        }}
                        type="text"
                        name="joinhost_code"
                        placeholder="Enter scheduled Room id"
                        value={this.state.joinhost_code}
                      />
                      {/* <img src={verogo} style={{ width: '30px', cursor: 'pointer' }} onClick={() => { this.joinhostnow() }} ></img>
                       */}

                      <button
                        style={{
                          backgroundColor: "#85bb65",
                          cursor: "pointer",
                          border: "none",
                          outline: "none",
                          width: "auto",
                          fontSize: "1.4rem",
                          color: "white",
                          fontWeight: "bold",
                        }}
                        className="enter-button-home"
                        onClick={() => {
                          this.joinhostnow();
                        }}
                      >
                        Enter
                      </button>
                    </div>
                    <br></br>
                    <div
                      style={{ display: "flex", justifyContent: "center" }}
                      id="create-a-new-room"
                    >
                      <p
                        style={{
                          width: "23%",
                          fontSize: "11px",
                          fontWeight: "bold",
                        }}
                      >
                        Create a New Room
                      </p>

                      <MuiThemeProvider theme={theme}>
                        <Tooltip title="HOST INFO">
                          <li style={{ fontStyle: "none" }}>
                            {/* <img src={infoicon} style={{ width: '30px' }} onClick={() => { this.infohostcreate() }} ></img>
                             */}
                            <button
                              onClick={() => {
                                this.infohostcreate();
                              }}
                              className="infoIcon"
                            >
                              ?
                            </button>
                          </li>
                        </Tooltip>
                      </MuiThemeProvider>

                      <input
                        className="checkmeet"
                        onChange={(event) => {
                          this.inputHandler(event);
                        }}
                        type="text"
                        name="room_name"
                        placeholder="Enter a Name for Your Room"
                      />
                      {/* <img src={verogo} style={{ width: '30px', cursor: 'pointer' }} onClick={() => { this.createRoom() }} ></img>
                       */}

                      <button
                        style={{
                          backgroundColor: "#85bb65",
                          cursor: "pointer",
                          border: "none",
                          outline: "none",
                          width: "auto",
                          fontSize: "1.4rem",
                          color: "white",
                          fontWeight: "bold",
                        }}
                        className="enter-button-home"
                        onClick={() => {
                          this.createRoom();
                        }}
                      >
                        Enter
                      </button>
                    </div>
                    <br></br>
                  </div>
                ) : null}
               
               

                <div style={{ display: "flex", justifyContent: "center" }}>
                  <p
                    style={{
                      width: "23%",
                      fontSize: "11px",
                      fontWeight: "bold",
                    }}
                  >
                    Attendee Only
                  </p>

                  <MuiThemeProvider theme={theme}>
                    <Tooltip title="ATTENDEE INFO">
                      <li style={{ fontStyle: "none" }}>
                        {/* <img src={infoicon} style={{ width: '30px' }} onClick={() => { this.infoattendeejoin() }} ></img>
                         */}
                        <button
                          onClick={() => {
                            this.infoattendeejoin();
                          }}
                          className="infoIcon"
                        >
                          ?
                        </button>
                      </li>
                    </Tooltip>
                  </MuiThemeProvider>

                  <input
                    className="checkmeet"
                    onChange={(event) => {
                      this.inputHandler(event);
                    }}
                    type="text"
                    name="room_code"
                    placeholder="Enter the Meeting Room id"
                  />

                  {/* <img src={verogo} style={{ width: '30px', cursor: 'pointer' }} onClick={() => { this.joinRoom() }} ></img>
                   */}

                  <button
                    style={{
                      backgroundColor: "#85bb65",
                      cursor: "pointer",
                      border: "none",
                      outline: "none",
                      width: "auto",
                      fontSize: "1.4rem",
                      color: "white",
                      fontWeight: "bold",
                    }}
                    className="enter-button-home"
                    onClick={() => {
                      this.joinRoom();
                    }}
                  >
                    Enter
                  </button>
                </div>
              </div>
            </form>

          
          </ul>
        </div>

     
        <div style={{ position: "absolute", top: "50px" }}>
          <Overlay
            open={this.state.open}
            onClose={() =>
              this.setState({
                open: false,
              })
            }
            closeOnClick
            style={{
              background: "rgba(0, 0, 0, 0.3)",
              display: "flex",
              alignitems: "center",
              justifycontent: "center",
            }}
          >
            {/* modal for public profile */}

            <div
              style={{
                width: "100%",
                backgroundColor: "white",
                color: "black",
                height: "700%",
              }}
            >
              <button
                style={{
                  position: "fixed",
                  right: "100px",
                  top: "50px",
                  zIndex: "100",
                }}
                onClick={() =>
                  this.setState({
                    open: false,
                  })
                }
              >
                Close
              </button>

              <div
                style={{
                  position: "absolute",
                  top: "50px",
                  justifyContent: "center",
                }}
              >
                <Privacypolicy />
              </div>
            </div>

            <div className="footer">
              <img src={copyrightlogo} style={{ width: "20px" }}></img>Megahoot,
              LLC All Rights Reserved
              <button
                onClick={() => this.openModal()}
                style={{
                  cursor: "pointer",
                  marginLeft: "20px",
                  backgroundColor: "black",
                  color: "white",
                  outline: "none",
                  border: "none",
                  fontSize: "1.6rem",
                }}
              >
                Privacy Policy
              </button>
              <button
                onClick={() => this.TermsCondition()}
                style={{
                  cursor: "pointer",
                  backgroundColor: "black",
                  color: "white",
                  outline: "none",
                  border: "none",
                  fontSize: "1.6rem",
                }}
              >
                Terms & Conditions
              </button>{" "}
            </div>

            {/* complete */}
          </Overlay>
        </div>
        <div className="footer">
          <img src={copyrightlogo} style={{ width: "20px" }}></img>MegaHoot Technologies, Inc All Rights Reserved
          <button
            onClick={() => this.openModal()}
            style={{
              cursor: "pointer",
              marginLeft: "20px",
              backgroundColor: "black",
              color: "white",
              outline: "none",
              border: "none",
              fontSize: "1.6rem",
            }}
          >
            Privacy Policy
          </button>
          <button
            onClick={() => this.TermsCondition()}
            style={{
              cursor: "pointer",
              backgroundColor: "black",
              color: "white",
              outline: "none",
              border: "none",
              fontSize: "1.6rem",
            }}
          >
            Terms & Conditions
          </button>{" "}
        </div>
      </div>
    );
  }
}

export default Home;
