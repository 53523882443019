import React, { Component } from "react";

import logo from '../verohivelogo.png';
import a from '../verifybadges/a.png';
import b from '../verifybadges/b.png';
import g from '../verifybadges/g.png';
import p from '../verifybadges/p.png';
import r from '../verifybadges/r.png';
import y from '../verifybadges/y.png';
import userpic from '../verifybadges/user.png';
import * as Api from '../api'
import Linkify from 'react-linkify';
import Toggle from 'react-toggle'
import Privacypolicy from "../privacypolicy";
import { Overlay } from 'react-portal-overlay';

import copyrightlogo from '../imgs/CopyrightVERO.png';


class contact extends Component {
  state = {
    username: "",
    id: "",
    privatekey: "",
    firstname: "",
    lastname: "",
    email: "",
    organization: "",
    bio: "",
    city: "",
    country: "",
    links: "",
    key: "",
    baconIsReady: false,
    followuser: [],
    write: "",
    following: [],
    mailsentalert: "",
    enterroomcode: "",
    createroomalert: "",
    connectedsuccessalert:"",
    youcannorfollowu:"",
    alreadysentreq:"",
    VEROrequestsentalert:"",

  };



  componentDidMount() {

    document.onkeydown = function(e) {
      if(e.keyCode == 123) {
      return false;
      }
      if(e.ctrlKey && e.shiftKey && e.keyCode == 'I'.charCodeAt(0)){
      return false;
      }
      if(e.ctrlKey && e.shiftKey && e.keyCode == 'J'.charCodeAt(0)){
      return false;
      }
      if(e.ctrlKey && e.keyCode == 'U'.charCodeAt(0)){
      return false;
      }
      }
    // const {username}=this.props;
    console.log("dd", this.props.location.state.username)
    var username = this.props.location.state.username
    this.setState({
      id: this.props.location.state.username
    })

    fetch("/getuser", {
      method: "post",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        username,

      })


    })
      .then(response => response.json())
      .then((res) => {
        console.log("ff", res)
        this.setState({
          privatekey: res.privateKey,
          firstname: res.firstName,
          lastname: res.lastName,
          email: res.email,
          organization: res.organization,
          verified: res.verified,
          usertype: res.userType,
          bio: res.bio,
          city: res.city,
          country: res.country,
          links: res.links,
          image1: res.ProfilePic

        })

      })
      .catch(err => console.log(err))


  }
  sendemail = (enteredemail) => {
    console.log("r", enteredemail)
    console.log("dd", this.props.location.state.username)
    var username = this.props.location.state.username
    this.setState({
      id: this.props.location.state.username
    })
    // const enteredemail = prompt("Enter email to invite ")
    // const enteredemail = this.state.emailinvite
    // navigator.clipboard.writeText(this.props.location.state.room_code);
    fetch("/getuser", {
      method: "post",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
       username,

      })


    })
      .then(response => response.json())
      .then((res) => {
        console.log("ff", res)
        this.setState({
          firstname: res.firstName,
          lastname: res.lastName,
        

        })

      })
      .catch(err => console.log(err));

    fetch("/nodemailer", {
      method: "post",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        To: enteredemail,
        subject: "VEROHive Message",
        text: "Your invitation" + this.state.write,
        html: `<a href="https://verohive.org/"><img src="https://farma-consumer.s3.ap-south-1.amazonaws.com/c230d91721664885cf6c0cf1b1c6c70f.png" style="width: 20%;"></a>
        
        <h3 style="color: #5b5b5b;">${this.state.firstname+" "+this.state.lastname} sent this message to you on VEROHive :</h3>

       <p style="color: #5b5b5b;">${this.state.write}</p>
       
     <h4>To respond please sign into <a href="https://verohive.org/">VEROHive</a></h4>
       
  
      <br>
      
       
        
        
        <h4 style="color: #757575;">Cheers!</h4>
        <h4 style="color: #757575;">VEROHive Team</h4>
        `

      })
    })

      .then(() => {

        console.log("ddd")
        //  this.verify()
        this.setState({ show: false })
        this.setState({ mailsentalert: "Mail sent successfully" })
        // alert("Mail sent successfully")
      })
      .catch(err => console.log(err))


  }
  handleChange(event) {
    // do something with event.target.checked
    this.setState({
      baconIsReady: event.target.checked
    })
  }
  recording = () => {
    this.props.history.push('/recording', {
      username: this.state.id
    })
  }

  sayHello1 = () => {
    this.props.history.push('/profile', {
      username: this.state.id
    })
  }

  openModal = () => {

    this.setState({
      open: true

    })
  }
  publicProfile = () => {
    this.props.history.push('/publicProfile', {
      username: this.state.id
    })
  }
  sayHello = () => {
    console.log("Hello")
    fetch("/logout", {
      method: "get",
      headers: {
        "Content-Type": "application/json"
      },

    })
      .then((res) => {
        localStorage.removeItem('user')
        this.props.history.push('/login')
        window.location.reload()
        console.log("ff", res)
      })
      .catch(err => console.log(err))
  }
  renderLoading() {
    return <div>Logging in...</div>;
  }
  onChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  joinRoom = () => {
    console.log('hello')
    if (
      !this.state.room_code) {
      return this.setState({ enterroomcode: "Enter the room Id" })
      // alert("Enter the room code");
    }
    // API.get('/rooms/join/'+this.state.room_code, {

    // }).then((res) => {
    // 	console.log("dd",res)
    // 	// this.props.flashHandler('success', 'Room Joined!');
    // 	this.setState({ room_name: res.data.data.room_name, type: 'client' });
    this.props.history.push({
      pathname: '/videochat',
      search: "?" + new URLSearchParams({ id: this.state.room_code }).toString(),
      state: {
        type: 'client',
        room_code: this.state.room_code,
        username: this.state.id,
        privatekey: this.state.privatekey,
        email: this.state.email,
        firstname: this.state.firstname,
        lastName: this.state.lastname

      }
    })
    // }).catch((error) => {
    // 	this.props.flashHandler('error', 'Room is full!');
    // });
  }
  createRoom = () => {
    console.log('hello')
    if (
      !this.state.room_name) {
    return this.setState({ createroomalert: "Enter the room name" })
      // return alert("Create the room name");
    }

    var rand, mailOptions, host, link;
    var rand1 = Math.floor((Math.random() * 100) + 54);
    var rand2 = Math.floor((Math.random() * 100) + 54);
    var rand3 = Math.floor((Math.random() * 100) + 54);
    var rand4 = Math.floor((Math.random() * 100) + 54);
    var rand5 = Math.floor((Math.random() * 100) + 54);

    rand = rand1.toString() + rand2.toString() + rand3.toString() + rand4.toString() + rand5.toString();
    // rand = rand1.toString()+ Math.random().toString(36) +rand2.toString()+ Math.random().toString(36);



    // if (this.state.room_name == '') {
    //   this.props.flashHandler('error', "You didn't enter a room name!");
    //   return;
    // }
    // var rand, mailOptions, host, link;
    // var rand1 = Math.floor((Math.random() * 100) + 54);
    // var rand2 = Math.floor((Math.random() * 100) + 54);
    // var rand3 = Math.floor((Math.random() * 100) + 54);
    // rand = rand1.toString() + rand2.toString() + rand3.toString();
    // let userData = JSON.parse(localStorage.getItem('userData'));
    // let userID = userData.id;

    // API.post('/rooms/create', {
    //   name: this.state.room_name,
    //   creatorId: userID
    // }).then((res) => {
    //   console.log(res.data.data.roomCode);
    //   this.setState({
    //     type: 'host',
    //     room_code: res.data.data.roomCode
    //   });
    //Should get room code here and set its state
    // this.props.flashHandler('success', 'Room Joined!');
    this.props.history.push({
      pathname: '/videochat',
      search: "?" + new URLSearchParams({ id: rand }).toString(),
      state: {
        type: 'host',
        room_code: rand,
        username: this.state.id,
        privatekey: this.state.privatekey,
        email: this.state.email,
        firstname: this.state.firstname,
        lastName: this.state.lastname
      }
      // })
      // this.props.history.push('/room', {
      // 	type: 'host',
      // 	room_code: res.data.data.roomCode
      //   });
    }).catch((error) => {
      console.log(error);
      this.props.flashHandler('error', 'An Error occured');
    });
  }
  inputHandler = (event) => {
    let field = event.target.name;
    let value = event.target.value;
    this.setState({
      [field]: value
    });
  }

  Home = () => {
    this.props.history.push('/private', {
      username: this.state.id
    })
  }
  viewfollow = () => {
    const { email, privatekey, key } = this.state;
    fetch("/follow/followrequests", {
      method: "post",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        privatekey
      })
    })
      .then(response => response.json())
      .then((res) => {
        console.log("Dd", res)
        this.setState({ followuser: res })
      })
      .catch(err => console.log(err))
  }

  acceptrequest = (email) => {
    const { firstname, lastname } = this.state;
    const fullnameaccepted = firstname + lastname
    const emailaccepted = this.state.email
    fetch("/follow/acceptrequests", {
      method: "post",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        email,
        emailaccepted,
        fullnameaccepted
      })
    })
      .then((res) => {
        // console.log("Dd", res)'
        this.setState({connectedsuccessalert:"connected successfully"})
        // alert("connected successfully")
        this.viewfollow()
        this.viewfollowing()
      })
      .catch(err => console.log(err))
  }
  follow = () => {
    const { email, privatekey, key, firstname, lastname } = this.state;
    var fullname = firstname + lastname
    if (key == privatekey) {
      // alert("You can't Request yourself")
      this.setState({youcannorfollowu:"You can't Request yourself"})

    }
    else {
      fetch("/follow", {
        method: "post",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({

          email,
          privatekey,
          fullname,
          key
        })
      })
        .then((res) => {
          console.log("rohan", res)
          if (res.status == 401) {
            this.setState({alreadysentreq:"already sent request"})
            // alert("already sent request")
          }
          else {
            this.setState({VEROrequestsentalert:"VERO request sent successfully"})
            // alert("VERO request sent successfully")



          }

        })
        .catch(err => console.log(err))
    }

  }
  viewfollowing = () => {
    this.setState({ following: [] })
    const privatekey = this.state.privatekey
    fetch("/follow/following", {
      method: "post",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        privatekey
      })
    })
      .then(response => response.json())
      .then((res) => {
        console.log("Dd", res)
        this.setState({ following: res })
      })
      .catch(err => console.log(err))
    fetch("/follow/followinga", {
      method: "post",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        privatekey
      })
    })
      .then(response => response.json())
      .then((res) => {
        console.log("Dd", res)
        if (this.state.following.length == undefined) {
          this.setState({ following: res })
        }
        else {
          console.log("here")
          for (var i = 0; i < res.length; i++) {
            this.state.following.push(res[i])
          }

          this.setState({ following: this.state.following })
        }


      })
      .catch(err => console.log(err))
  }
  // Upload1_To_AWS_S3 = () => {
  //   var that = this;
  //   const { email } = this.state;
  //   console.log("Dddd", this.state.image)
  //   let formData = new FormData();
  //   formData.append("photo", this.state.image);
  //   try {
  //     const res = Api.uploadImageToAwsS3(formData)
  //     res.then(function (value) {
  //       console.log(value); // "Success"
  //       // that.setState({
  //       //   image: value
  //       // })
  //       fetch("/updateProfilePic", {
  //         method: "post",
  //         headers: {
  //           "Content-Type": "application/json"
  //         },
  //         body: JSON.stringify({
  //           email,
  //           value

  //         })
  //       })

  //         .then(() => {

  //           alert("profile pic changed successfully")
  //           that.setState({
  //             image1: value
  //           })

  //         })
  //         .catch(err => console.log(err))
  //     })



  //   } catch (e) {

  //   }

  // }

  // async onSubmit(e) {
  //   e.preventDefault();
  //   const { username } = this.state;

  //   this.props.history.push({
  //     pathname: '/videochat',
  //     search: "?" + new URLSearchParams({ id: username }).toString(),
  //     state: { username: this.state.id }
  //   })




  // }


  render() {

    const openMenu = () => {

      document.querySelector(".sidebar").classList.add("open");
    };

    const closeMenu = () => {

      document.querySelector(".sidebar").classList.remove("open");
    }

    const opencontact = () => {

      document.querySelector(".contactbar").classList.add("open");
    };

    const closecontact = () => {

      document.querySelector(".contactbar").classList.remove("open");
    }

    const componentDecorator = (href, text, key) => (
      <a href={href} key={key} target="_blank" rel="noopener noreferrer">
        {text}
      </a>


    );

    return (
      <div className="home-main">
        <div className="header">
          <div className="brand">
            <button onClick={openMenu}>&#9776;</button>


          </div>
          <img src={logo} className='logo-vero'></img><h4 style={{ color: 'white', marginRight: '45%' }}>V4.1.1</h4>
        </div>
        {
          this.state.mailsentalert != "" ? (
            <div style={{ zIndex: "10000000", backgroundColor: "white", padding: '10px', color: 'grey', fontSize: '2.4rem', position: 'absolute', top: '30%', left: '40%', width: '300px', height: '200px' }}>
              <br></br><br></br>  <span> {this.state.mailsentalert}<br></br></span>
              <span><button style={{ backgroundColor: "#4FADD3", color: 'white', border: 'none', width: '100px' }}
                onClick={() => this.setState({
                  mailsentalert: "",

                })}>OK</button></span>
            </div>
          ) : null
        }

{
          this.state.connectedsuccessalert != "" ? (
            <div style={{ zIndex: "10000000", backgroundColor: "white", padding: '10px', color: 'grey', fontSize: '2.4rem', position: 'absolute', top: '30%', left: '40%', width: '300px', height: '200px' }}>
              <br></br><br></br>  <span> {this.state.connectedsuccessalert}<br></br></span>
              <span><button style={{ backgroundColor: "#4FADD3", color: 'white', border: 'none', width: '100px' }}
                onClick={() => this.setState({
                  connectedsuccessalert: "",

                })}>OK</button></span>
            </div>
          ) : null
        }

{
          this.state.createroomalert != "" ? (
            <div style={{ zIndex: "10000000", backgroundColor: "white", padding: '10px', color: 'grey', fontSize: '2.4rem', position: 'absolute', top: '30%', left: '40%', width: '300px', height: '200px' }}>
              <br></br><br></br>  <span> {this.state.createroomalert}<br></br></span>
              <span><button style={{ backgroundColor: "#4FADD3", color: 'white', border: 'none', width: '100px' }}
                onClick={() => this.setState({
                  createroomalert: "",

                })}>OK</button></span>
            </div>
          ) : null
        }
        {
          this.state.youcannorfollowu != "" ? (
            <div style={{ zIndex: "10000000", backgroundColor: "white", padding: '10px', color: 'grey', fontSize: '2.4rem', position: 'absolute', top: '30%', left: '40%', width: '300px', height: '200px' }}>
              <br></br><br></br>  <span> {this.state.youcannorfollowu}<br></br></span>
              <span><button style={{ backgroundColor: "#4FADD3", color: 'white', border: 'none', width: '100px' }}
                onClick={() => this.setState({
                  youcannorfollowu: "",

                })}>OK</button></span>
            </div>
          ) : null
        }
          {
          this.state.alreadysentreq != "" ? (
            <div style={{ zIndex: "10000000", backgroundColor: "white", padding: '10px', color: 'grey', fontSize: '2.4rem', position: 'absolute', top: '30%', left: '40%', width: '300px', height: '200px' }}>
              <br></br><br></br>  <span> {this.state.alreadysentreq}<br></br></span>
              <span><button style={{ backgroundColor: "#4FADD3", color: 'white', border: 'none', width: '100px' }}
                onClick={() => this.setState({
                  alreadysentreq: "",

                })}>OK</button></span>
            </div>
          ) : null
        }

         {
          this.state.VEROrequestsentalert != "" ? (
            <div style={{ zIndex: "10000000", backgroundColor: "white", padding: '10px', color: 'grey', fontSize: '2.4rem', position: 'absolute', top: '30%', left: '40%', width: '300px', height: '200px' }}>
              <br></br><br></br>  <span> {this.state.VEROrequestsentalert}<br></br></span>
              <span><button style={{ backgroundColor: "#4FADD3", color: 'white', border: 'none', width: '100px' }}
                onClick={() => this.setState({
                  VEROrequestsentalert: "",

                })}>OK</button></span>
            </div>
          ) : null
        }

        {
          this.state.enterroomcode != "" ? (
            <div style={{ zIndex: "10000000", backgroundColor: "white", padding: '10px', color: 'grey', fontSize: '2.4rem', position: 'absolute', top: '30%', left: '40%', width: '300px', height: '200px' }}>
              <br></br><br></br>  <span> {this.state.enterroomcode}<br></br></span>
              <span><button style={{ backgroundColor: "#4FADD3", color: 'white', border: 'none', width: '100px' }}
                onClick={() => this.setState({
                  enterroomcode: "",

                })}>OK</button></span>
            </div>
          ) : null
        }
        <aside className="sidebar">
          <h4 className="welcome-user">
            <div style={{ width: "50px", height: "45px", backgroundColor: "#034063", marginLeft: '100px',borderRadius:"64px" }}>

              {this.state.image1 ? <img src={this.state.image1} style={{ width: "50px", height: "45px",borderRadius:"64px" }} /> : (<div>


              </div>)}
              {/* {this.state.image1 ? (<button

  className="btn btn-sendfile" style={{ backgroundColor: "red" }}
  onClick={() => this.setState(
    {
      image1: null
    }
  )}>Change</button>) : null} */}
            </div>
          Welcome {this.state.id} </h4>
          <button
            className=""
            onClick={() => this.sayHello()}>Logout</button>
          <button onClick={closeMenu} className="sidebar-close-btn">X</button>

          <hr></hr>
          <ul className="sidebar-ui">
            <li><a
              className=" btn-profile "
              onClick={() => this.Home()}>Dashboard</a></li>

            {/* <li  ><a className=" btn-profile ">Whiteboard</a></li> */}
            {/* <li ><a className=" btn-profile " >Screenshare</a></li> */}


            {/* <li><a href="https://recordmeeting.verohive.org/" target="_blank"
              className=" btn-profile "
            >Recording</a></li> */}
            <li><a
              className=" btn-profile "
              onClick={() => this.sayHello1()}>Update Profile</a></li>

            <li><a
              className=" btn-profile "
              onClick={() => this.publicProfile()}>Public Profile</a></li>

            <li><a
              className=" btn-profile "
            >Background</a></li>
            {/* <li><a
              className=" btn-profile " onClick={opencontact}
             >Contacts</a></li> */}

            <li><a
              className=" btn-profile "
            >Contacts</a></li>
            <li><a
              className=" btn-profile "
            >Schedule Meeting</a></li>
            <li><a
              className=" btn-profile "
            >Chat</a></li>


          </ul>

          <hr></hr>

       





        </aside>




        <div>

        </div>










        <div className="contactbar">

          <div >
            <h4>Enter VERO Number</h4>
            <input type="text" placeholder="Vero Number" name="key" onChange={(event) => { this.inputHandler(event) }} />
            <button onClick={() => this.follow()} style={{backgroundColor:'green',border:'none',color:'white',outline:'none'}}>Send request</button>
          </div>
          <br></br>

          <div>

            <button onClick={() => this.viewfollow()} style={{backgroundColor:'blue',border:'none',color:'white',outline:'none'}}>View Contact requests</button>
            {this.state.followuser && this.state.followuser.length > 0
              ? this.state.followuser.map((value, index) => {
                if (index < 10) {
                  return (
                    <div>
                      <span> {value.fullnamerequested}</span>
                      <span><button  onClick={() => this.acceptrequest(value.emailrequested)} style={{backgroundColor:'green',border:'none',color:'white',outline:'none'}}>Accept request</button></span>

                    </div>

                  );
                }
              })
              : null}
          </div>
          <br></br>
          <hr></hr>
          <div>

            <button onClick={() => this.viewfollowing()} style={{backgroundColor:'blue',border:'none',color:'white',outline:'none'}}>View Contacts</button><br></br>
            {this.state.following && this.state.following.length > 0
              ? this.state.following.map((value, index) => {
                if (index < 10) {
                  return (
                    <div style={{ backgroundColor: "#06476D", display: 'flex', justifyContent: 'space-between' }}>
                      <span style={{ marginLeft: '20px' }}> {value.fullnamerequested == this.state.firstname +this.state.lastname ? (value.fullnameaccepted) : (value.fullnamerequested)}</span>
                      {/* <span>Enter </span> */}
                      {/* <button onClick={()=>this.setState({
                          show:true
                        })}>Send</button> */}
                      {/* {this.state.show?():null}   */}
                      <div className="sendmsgcontact">
                        <input
                          type="text"
                          placeholder="Type Your Message"
                          name="write"
                          onChange={(event) => { this.inputHandler(event) }}
                        />
                        <span>
                          {value.emailrequested == this.state.email ? (
                            <button style={{marginRight:'10px'}} onClick={() => this.sendemail(value.emailaccepted)}>Send</button>) : (<button onClick={() => this.sendemail(value.emailrequested)}>Send</button>)}</span>
                      </div>

                    </div>


                  );
                }
              })
              : null}
          </div>
        </div>
        <div style={{ position: 'absolute', top: '50px' }}>
          <Overlay
            open={this.state.open}
            onClose={() => this.setState({
              open: false
            })}
            closeOnClick
            style={{
              "background": "rgba(0, 0, 0, 0.3)",
              "display": "flex",
              "alignitems": "center",
              "justifycontent": "center"
            }}
          >
            {/* modal for public profile */}

            <div style={{ width: '100%', backgroundColor: 'white', color: 'black', height: '700%' }}>

              <button style={{ position: 'fixed', right: '100px', top: '50px', zIndex: '100' }} onClick={() => this.setState({
                open: false
              })}>Close</button>

              <div style={{ position: 'absolute', top: '50px', justifyContent: 'center' }}>

                <Privacypolicy />
              </div>
            </div>

            <div className='footer'><img src={copyrightlogo} style={{ width: '20px' }} ></img>MegaHoot Technologies, Inc All Rights Reserved
         <button onClick={() => this.openModal()} style={{ cursor: 'pointer', marginLeft: '20px', backgroundColor: '#033A5A', color: 'white', outline: 'none', border: 'none', fontSize: '1.6rem' }} >Privacy Policy</button>
              <button onClick={() => this.TermsCondition()} style={{ cursor: 'pointer', backgroundColor: '#033A5A', color: 'white', outline: 'none', border: 'none', fontSize: '1.6rem' }} >Terms & Conditions</button> </div>

            {/* complete */}


          </Overlay>
        </div>
        <div className='footer'><img src={copyrightlogo} style={{ width: '20px' }} ></img>MegaHoot Technologies, Inc All Rights Reserved
         <button onClick={() => this.openModal()} style={{ cursor: 'pointer', marginLeft: '20px', backgroundColor: '#033A5A', color: 'white', outline: 'none', border: 'none', fontSize: '1.6rem' }} >Privacy Policy</button>
          <button onClick={() => this.TermsCondition()} style={{ cursor: 'pointer', backgroundColor: '#033A5A', color: 'white', outline: 'none', border: 'none', fontSize: '1.6rem' }} >Terms & Conditions</button> </div>

      </div>
    );
  }
}

export default contact;